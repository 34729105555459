import { Injectable } from '@angular/core';
import { tap, map, catchError } from 'rxjs/operators';
import { _throw as throwError } from 'rxjs/observable/throw';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_URL } from '../../config/config';
import { ListaPrecio } from '../../models/listaPrecio.model';
import Swal from 'sweetalert2';
@Injectable()
export class ListaPrecioService {

  lista: ListaPrecio[] = [];

  headers: HttpHeaders; // new HttpHeaders();

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { }

  traerListaPrecio(codeEmpresa) {
    let url = BASE_URL + 'listaprecio?codempresa=' + codeEmpresa;
    return this.http.get(url)
      .map((resp: any) => resp);
  }

  delete(id: number): Observable<ListaPrecio> {
    return this.http.delete<ListaPrecio>(BASE_URL + 'listaprecio' + `/${id}`).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getById(id): Observable<ListaPrecio> {
    return this.http.get<ListaPrecio>(BASE_URL + 'listaprecio' + `/${id}`).pipe(
      catchError(e => {
        this.router.navigate(['/listaPrecio']);
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getListEcommerce(): Observable<ListaPrecio> {
    console.log('GET LISTA ECOMMERCE');
    return this.http.get<ListaPrecio>(BASE_URL + 'listaprecio/ecommerce' ).pipe(
      catchError(e => {
         console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  create(lista: ListaPrecio): Observable<ListaPrecio> {
    return this.http.post(BASE_URL + 'listaprecio', lista)
      .pipe(
        map((response: any) => response.listaPrecio as ListaPrecio),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }
  update(lista: ListaPrecio): Observable<any> {
    return this.http.put<any>(BASE_URL + 'listaprecio', lista).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }


}

export class ClienteGoogle {
    //  ? significa opcional
    public id: number;
    public name: string;
    public address: string;
    public latitud: number;
    public longitud: number;

    constructor() { }
}


export class GrupoMaterial {
        //  ? significa opcional
        public codGrupoErp: string;
        public descripcion: string;
        public codEmpresa: number;
        public fechaCreacion?: string;
        public fechaModificacion?: string;
        public codUsuarioCreacion?: string;
        public codUsuarioModificacion?: string;
        constructor() { }

}

<!-- ============================================================== -->
<!--                          PEDIDO                                 -->
<!-- ============================================================== -->
<div class="row" *ngIf="canje">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Canje</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="canje">
                            <thead>
                                <tr>
                                    <th>Codigo</th>
                                    <th>Nro canje</th>
                                    <th>Cliente</th>
                                   
                                    <th>F. Canje</th>
                                    <th>F. Modificacion</th>
                                    <th>F. Anulacion</th>
                                    <th>Puntos</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ canje.codCanje }}
                                    </td>
                                    <td>
                                        <h6> {{canje.nroCanje.toString().padStart(8,"00000000") }}</h6>
                                    </td>

                                    <td>
                                        <h6>{{canje.cliente.docNro}}</h6>
                                        <small class="text-muted">{{ canje.cliente.razonSocial }}</small>
                                    </td>
                                     
                                    <td>
                                        <h6>
                                            {{ canje.fechaCanje | date:'dd/MM/yyyy' }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ canje.fechaModificacion | date:'dd/MM/yyyy, h:mm a' }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6 *ngIf="!canje.fechaAnulacion">
                                            xx/xx/xxxx
                                        </h6>
                                        <h6 *ngIf="canje.fechaAnulacion">
                                            {{ canje.fechaAnulacion | date:'dd/MM/yyyy, h:mm a' }}
                                        </h6>
                                    </td>

                                    <td>
                                        <h6>
                                            {{ canje.puntos | number }} 
                                        </h6>
                                    </td>
                                     
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- ============================================================== -->
<!--                    Detalles de Canje                           -->
<!-- ============================================================== -->
<div class="row" *ngIf="detalles?.length>0">
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Detalles</h4>
 
                </div>
                <perfect-scrollbar class="scroll-container"
                    style="position: relative; max-width: 100%; max-height: 600px;" [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <div class="table-responsive m-t-20">
                            <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="detalles">
                                <thead>
                                    <tr>
                                        <th colspan="3">Premio</th>
                                      
                                        <th>Cantidad</th>
                                       
                                        <th>Puntos</th>
                                        <th>Total</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalle of detalles">
                                        <td>
                                            {{ detalle.nroItem }}
                                        </td>
                                        <td align="center" class="w70" align="center" style="background: white">
                                            <img *ngIf="detalle.premio.img"
                                                [attr.src]="detalle.premio.img | imagen:'premios' | async"
                                                class="img-producto60" />
                                            <img *ngIf="!detalle.premio.img" [src]="sinImagen" class="img-producto60">
                                        </td>
 
                                        <td>
                                            <h6>{{ detalle.premio.codPremioErp }}</h6><small class="text-muted">{{
                                                detalle.premio.descripcion }}</small>
                                        </td>
                                        
                                        <td>
                                            <h6>
                                                {{ detalle.cantidad | number }}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.puntos | number }}  
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.totalPuntos | number }}
                                            </h6>
                                        </td>
                                         
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>



 

 
 
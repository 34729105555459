<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!precioMaterial.codPrecioMaterial" class="m-b-0 text-white">Crear precio material </h4>
        <h4 *ngIf="precioMaterial.codPrecioMaterial" class="m-b-0 text-white">Actualizar precio material </h4>
    </div>
    <div class="card-body">

        <form #precioForm="ngForm">
 
           
            <div class="form-group row">
                <label for="productos" class="col-form-label col-sm-2">Producto:</label>
                <div class="col-sm-6">
                    <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto" style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)"></ng-select-producto>
                </div>
            </div>

            <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal:</label>
                <div class="col-sm-6">
                    <ng-select-sucursal #searchSucursales id="selectSucursal" [cargadorSucursal]="cargadorSucursal" style="width: 100%" name="sucursal" (retornoObjeto)="seleccionarSucursal($event)"></ng-select-sucursal>
                </div>
            </div>
 
            <div class="form-group row">
                <label for="precios" class="col-form-label col-sm-2">Precio <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="precioMaterial.precioCosto" name="precioCosto" required #precioCosto="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="precioCosto.invalid && (precioCosto.dirty || precioCosto.touched)">
                    <div *ngIf="!!precioCosto?.errors.required">
                        precio costo es requerido
                    </div>
                </div>
            </div>
       

            
 
            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/preciosMateriales']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!precioMaterial.codPrecioMaterial else elseBlock" [disabled]="!precioForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!precioForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>

        </form>

    </div>
</div>
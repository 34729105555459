<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!punto.codPunto" class="m-b-0 text-white">Crear punto</h4>
        <h4 *ngIf="punto.codPunto" class="m-b-0 text-white">Actualizar punto</h4>
    </div>
    <div class="card-body">

        <form #puntoForm="ngForm">



            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Descripcion <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="punto.descripcion"
                        (ngModelChange)="punto.descripcion =  toUpeCaseEvent($event)" name="descripcion"
                        #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion?.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion?.errors.minlength">
                            descripcion debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="puntos" class="col-form-label col-sm-2">Punto <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="punto.puntos" name="puntos"
                        #puntos="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaDesde" class="col-form-label col-sm-2">Fecha Desde <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="punto.fechaDesde" name="fechaDesde"
                        #fechaDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaHasta" class="col-form-label col-sm-2">Fecha Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="punto.fechaHasta" name="fechaHasta"
                        #fechaHasta="ngModel">

                </div>
            </div>

            <div class="form-group row">
                <label for="importeDesde" class="col-form-label col-sm-2">Importe Desde<span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="punto.importeDesde" name="importeDesde"
                        #importeDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="importeHasta" class="col-form-label col-sm-2">Cantidad Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="punto.importeHasta" name="importeHasta"
                        #importeHasta="ngModel">

                </div>
            </div>


            <div class="form-group row">
                <label for="activo" class="col-form-label col-sm-2">Activo <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
 
                    <ng-select [(ngModel)]="punto.activo" name="activo" id="activo" #activo="ngModel"
                        [clearable]="false">
                        <ng-option [value]="true">SI</ng-option>
                        <ng-option [value]="false">NO</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="listaPrecio" notFoundText="No se ha encontrado lista"
                        placeholder="Seleccione lista" [(ngModel)]="punto.listaPrecio" name="listaPrecio"
                        bindLabel="descripcion" (clear)="cleanLista()" (change)="cambioListaPrecio($event)">
                    </ng-select>

                </div>
            </div>
            <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="sucursales" notFoundText="No se ha encontrado sucursal"
                        placeholder="Seleccione sucursal" [(ngModel)]="seleccionSucursal" name="sucursal"
                        bindLabel="nombreSucursal" (clear)="cleanSucursal()" (change)="cambioSucursal($event)">
                    </ng-select>
                </div>
            </div>
 
            
            <div class="form-group row">
                <label for="tipo" class="col-form-label col-sm-2">Tipo  <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
   
                    <ng-select [(ngModel)]="punto.tipo" name="tipo" id="tipo"
                        #tipo="ngModel" [clearable]="false">
 
                        <ng-option [value]="'ESCALA'">ESCALA</ng-option>
                        <ng-option [value]="'PRODUCTO'">PRODUCTO</ng-option>
                    </ng-select>
                </div>
            </div>

 

            <div class="form-group row"
                *ngIf="punto.tipo === 'PRODUCTO' ">
                <label for="productos" class="col-form-label col-sm-2">Productos</label>
                <div class="col-sm-6">
                    <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto"
                        style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)">
                    </ng-select-producto>
                </div>

            </div>

            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/puntos']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                        *ngIf="!punto.codPunto else elseBlock" [disabled]="!puntoForm.form.valid"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!puntoForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>
<ng-template #rt let-r="result"  >
  <ngb-highlight [result]="r.nombreProducto"></ngb-highlight>
</ng-template>
<div class="form-group">
     <div class="input-group" style="width: 100%;"  >
        <input id="typeahead-productos" 
           #inputProducto (keyup)="quitarProducto(inputProducto.value)"type="text"  style="width: 100%;" 
          [class.is-invalid]="searchFailed" class="form-control"
          (selectItem)="selectedItem($event.item)" 
          [(ngModel)]="cargadorProducto" [resultTemplate]="rt"        
          [inputFormatter]="formatter" [ngbTypeahead]="search" 
          (focus)="onFocus($event)"
          placeholder="Buscar producto" />
<!--         <div class="input-group-addon" ><i class="fa fa-search"></i></div>
 -->   </div>
    <div class="alert alert-danger" *ngIf="mensaje">
     producto es requerido
    </div>
    <span *ngIf="searching">Buscando...</span>
    <div class="invalid-feedback" *ngIf="searchFailed">
      Lo sentimos, no se pudieron cargar las sugerencias.</div>
  </div>
  
 <!--   <hr>
  <pre>Model: {{ model | json }}</pre> -->
export class Chofer {

    //  ? significa opcional
    public codChofer: number;
    public codChoferErp: string;
    public codEmpresa: number;
    public chofer: string;
    public docNro: string;
    public tipoLicencia: string;
    public licencia: string;
    public codUltimoReparto: number;
    public activo?: boolean;
    public fechaCreacion?: string;
    public fechaModificacion?: string;
    public concatDocChofer?: string;
    constructor() { }
}

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CobranzaDetalle } from '../../models/cobranzaDetalles.model';
import { Cliente } from '../../models/cliente.model';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ErrModel } from '../../models/ErrModel.model';
import { Usuarios } from '../../models/usuarios.model';
import { Sucursal } from '../../models/sucursal.model';
import { MotivoAnulacionService } from '../../services/motivoAnulacion/motivoAnulacion.service';
import { User } from '../../models/user.model';
import { LoginService, SucursalesService, UsuarioService, PedidosService, ExcelService } from '../../services/service.index';
import { MotivoAnulacion } from '../../models/motivoAnulacion.model';
import { ObjetoSelector } from '../../models/ObjetoSelector';
import { Pedido } from '../../models/pedido.model';
import { PedidoDetalle } from '../../models/PedidoDetalle.model';
import { TotalModel } from '../../models/totalModel';
import { AbiOrdenService } from '../../services/abi-orden/abi-orden.service';
import { ABI_Pedido } from 'src/app/models/abi-pedido.model';

// guardarhistorial
interface PedidoStorage {
  fechaInicio: string;
  fechaFin: string;
  cargadorCliente: Cliente;
  cargadorUsuario: Usuarios;
  cargadorSucursal: Sucursal;
  size: number;
  page: number;
}

interface OrdenAbi {
  orden_venta_id: number;
  punto_retiro_id: number;
  fecha_retiro: string;
}
@Component({
  selector: 'app-list-pedido',
  templateUrl: './pedidoLista.component.html',
  styles: [``]
})
export class PedidoListaComponent implements OnInit {
  elementType = 'url';
  value: string = null;
  user: User;
  fechaRetiro: string;
  modal: string = 'oculto';
  modalQR: string = 'oculto';
  tamanhoPag: string = 'md';
  cargadorUsuario: Usuarios;
  cargadorCliente: Cliente;
  cargadorSucursal: Sucursal;
  confirmacionOrden: OrdenAbi;
  pedidoStorage: PedidoStorage = null; // guardarhistorial
  objeto ={ tipoPedido:''};
  seleccionMotivo: number;
  size: number;
  codUsuario: number;
  codPedido: number;
  fechaInicio: string;
  fechafin: string;
  cliente: Cliente;
  usuario: Usuarios;
  sucursal: Sucursal;
  sinResultado: boolean = false;
  cargando: boolean = false;
  oculto1: string = 'oculto';
  oculto2: string = 'oculto';
  detalles: PedidoDetalle[] = [];
  sucursales: Sucursal[] = [];
  usuarios: Usuarios[] = [];
  cobranzaDetalles: CobranzaDetalle[] = [];
  motivosAnulacion: MotivoAnulacion[] = [];
  mAnulacion: MotivoAnulacion;
  pedidos: Pedido[] = [];
  paginador: any;
  errores: ErrModel[] = [];
  paginas = [];
  sinImagen: string = './assets/images/sin-imagen.jpg';
  busqueda: string = '';
  totalElementos: number = 0;
  ellipses: boolean = false;
  cantidadElementos: number = 0;
  pagina: number = 0;
  rutaPaginador: string = '/pedidoLista/page';
  rutaDetalles: string = '/pedidoLista/id';
  codSucursal: number;
  seleccionUsuario: number;
  page: number = 0;
  rol: string;
  totalkg: number = 0;
  totalGs: number = 0;
  nroPedido: number = 0;
  tipoPedido: string = '';
  public numeros: ObjetoSelector[] = [
    { cod: 10, descripcion: '10', enum: '10' },
    { cod: 15, descripcion: '15', enum: '15' },
    { cod: 20, descripcion: '20', enum: '20' },
    { cod: 25, descripcion: '25', enum: '25' },
    { cod: 30, descripcion: '30', enum: '30' },
    { cod: 40, descripcion: '40', enum: '40' },
    { cod: 50, descripcion: '50', enum: '50' },
    { cod: 100, descripcion: '100', enum: '100' }
  ];

  constructor(private _pedidosService: PedidosService,
    public _loginServices: LoginService,
    public _excelService: ExcelService,
    public _abiServices: AbiOrdenService,
    private _AnulacionService: MotivoAnulacionService,
    private _sucursalesServices: SucursalesService,
    private _usuariosServices: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    public http: HttpClient
  ) { }

  async ngOnInit() {
    this.nroPedido = 0;
    this.tipoPedido = '';
    this.totalkg = 0;
    this.totalGs = 0;
    this.codSucursal = 0;
    this.size = 20;
    this.cargadorCliente = null;
    this.cargadorUsuario = null;
    this.cargadorSucursal = null;
    this.rol = this._loginServices.user.authorities[0];
    if (this.rol == 'ROLE_CAJERO') {
      this.codSucursal = this._loginServices.user.codSucursal;
      this.cargarSucursalPorId(this.codSucursal);
      this.cargarUsuarios(this.codSucursal);
    }
    this.codPedido = 0;
    console.log('rol,', this.rol);
    this.user = this._loginServices.user;
    this.fechaInicio = moment(new Date()).format('YYYY-MM-DD');
    this.fechafin = moment(new Date()).format('YYYY-MM-DD');
    this.cargando = false;
    this.sinResultado = false;
    /******************storage */
    if (localStorage.getItem('pedidoStorage')) { // guardarhistorial
      this.pedidoStorage = JSON.parse(localStorage.getItem('pedidoStorage'));
      this.pagina = +localStorage.getItem('page');
      this.fechaInicio = this.pedidoStorage.fechaInicio,
        this.fechafin = this.pedidoStorage.fechaFin,
        this.cargadorCliente = this.pedidoStorage.cargadorCliente,
        this.cargadorUsuario = this.pedidoStorage.cargadorUsuario,
        this.cargadorSucursal = this.pedidoStorage.cargadorSucursal,
        this.size = this.pedidoStorage.size;
      this.page = this.pagina - 1;
      this.router.navigate([this.rutaPaginador, this.page]);
      this.loadPage(this.pagina);
    } else {
      this.router.navigate([this.rutaPaginador, this.page]);
      this.listar(this.page);
    }

    /*==========Observa la paginación =======================*/
    this.router.navigate([this.rutaPaginador, this.page]);
    this.activatedRoute.paramMap.subscribe(params => {
      this.page = +params.get('page');
      if (!this.page) {
        this.page = 0;
        this.router.navigate([this.rutaPaginador, this.page]);
      }
      this.listar(this.page);
    });
    /*=====================================================*/
  }

  loadPage(page: number) {
    if (page !== this.paginador) {
      this.paginador = page - 1;
      this.router.navigate([this.rutaPaginador, this.paginador]);
    }
  }

  buscar() {
    this.pagina = 1;
    this.loadPage(1);
    this.page = 0;
    this.router.navigate([this.rutaPaginador, 0]);
    this.cargando = true;
    this.listar(0);
  }

  listar(page) {
    let codSucursal = 0;
    if (this.codSucursal) {
      codSucursal = this.codSucursal;
    }
    this._pedidosService.findByFecha(
      page,
      this.fechaInicio,
      this.fechafin,
      this.cargadorCliente,
      this.cargadorUsuario,
      codSucursal,
      this.size,
      null, null, this.tipoPedido, this.nroPedido
    )
      .subscribe(async (r: any) => {
        console.log(r.content);
        this.pedidos = r.content as Pedido[];
        this.paginador = r;
        this.totalElementos = r.totalElements;
        this.cantidadElementos = r.size;
        localStorage.removeItem('pedidoStorage'); // guardarhistorial
        localStorage.removeItem('page'); // guardarhistorial
        let traerTotales: TotalModel = await this.findTotal();
        this.totalGs = traerTotales.totalGs;
        this.totalkg = traerTotales.totalKg;
        console.log('traerTotales', traerTotales);
        if (this.paginador.empty === true) {
          this.sinResultado = true;
          this.pedidos = [];
          this.cargando = false;
        } else {
          this.cargando = false;

        }
      });
  }

  export(): void {
    if (this.pedidos.length > 0) {
      console.log(this.pedidos);
      this._excelService.exportAsExcelFile(this.pedidos, 'xlsx');
    } else {
      this.invalido('No se puede exportar datos de la nada!!!');
    }

  }

  verTodos() {
    this.cargadorCliente = null;
    this.cargadorUsuario = null;
    this.cargadorSucursal = null;
    this.fechaInicio = moment('2019-01-01').format('YYYY-MM-DD');
    this.fechafin = moment(new Date()).format('YYYY-MM-DD');
    this.listar(0);
  }



  cambioNumero(EVENTO) {
    this.size = EVENTO;
  }


  editar(param) {// guardarhistorial
    this.activatedRoute.paramMap.subscribe(params => {
      let page: number = +params.get('page');
      if (!page) {
        page = 0;
      }
      localStorage.setItem('page', JSON.stringify(page + 1));
      console.log('page', page);
    });
    this.pedidoStorage = {
      page: 0,
      fechaInicio: this.fechaInicio,
      fechaFin: this.fechafin,
      cargadorCliente: this.cargadorCliente,
      cargadorUsuario: this.cargadorUsuario,
      cargadorSucursal: this.cargadorSucursal,
      size: this.size
    };

    localStorage.setItem('pedidoStorage', JSON.stringify(this.pedidoStorage));
    this.router.navigate([this.rutaDetalles, param]);
  }


  anular(pedido: Pedido) {
    if (pedido && (pedido.estado != 'CONCRETADO' && pedido.anulado != true)) {
      if (this._loginServices.user.authorities[0]== 'ROLE_VENDEDOR' && pedido.codUsuarioCreacion != this._loginServices.user.codUsuario) {
        this.invalido('No tiene permiso para realizar la anulación !!!');
        return;
      }
      Swal.fire({
        title: 'Está seguro?',
        text: `¿Seguro que desea anular el pedido?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, anular!',
        cancelButtonText: 'No, cancelar!',
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this._pedidosService.anular(pedido.codPedido)
            .subscribe(
              json => {
                this.listar(this.page);
                swal.fire('Pedido anulado ', 'Pedido anulado con exito', 'success');
              },
              err => {
                if (!err.error) {
                  this.error('500 (Internal Server Error)');
                  return;
                }
                this.errores = err.error.errors;
                console.error('Código del error desde el backend: ' + err.status);
              }
            );

        }
      });
    } else {
      this.invalido('Verifica que el pedido no se haya concretado o anulado !!!');
    }
  }

  seleccionarUsuario(item: Usuarios) {
    this.usuario = item;
    this.cargadorUsuario = item;
  }

  seleccionarCliente(item: Cliente) {
    this.cliente = item;
    this.cargadorCliente = item;
  }
  seleccionarSucursal(item: Sucursal) {
    this.sucursal = item;
    this.cargadorSucursal = item;
    (this.cargadorSucursal) ? this.codSucursal = this.cargadorSucursal.codSucursal : 0;
  }
  error(err) {
    this.toastr.error(err, 'Error',
      { timeOut: 2500 });
  }

  mostrarModal(pedido: Pedido) {
    this.modal = '';
    this.confirmacionOrden = null;
    this.confirmacionOrden = {
      orden_venta_id: pedido.codOrdenAbi,
      punto_retiro_id: pedido.codPuntoRetiro,
      fecha_retiro: null,
    }
    // this._AnulacionService.traerByCodEmp(this.user.codEmpresa)
    //   .subscribe((r: any) => {
    //     this.motivosAnulacion = r as MotivoAnulacion[];
    //     this.modal = '';
    //     console.log(this.motivosAnulacion);
    //     return;
    //   });
  }

  mostrarModalQr(pedido: Pedido) {
    this.modalQR = '';
    this.value = pedido.contrasena;

  }

  cerrarModalQr() {
    this.modalQR = 'oculto';
    this.value = null;
  }

  async enviar() {
    if (!this.fechaRetiro) {
      this.invalido('Debe seleccionar motivo de anulación');
      return;
    }
    this.confirmacionOrden.fecha_retiro = moment(new Date(this.fechaRetiro)).format();
    this._abiServices.aprobarOrden(this.confirmacionOrden).subscribe(
      j => {
        this._pedidosService.confirmarFechaRetiro(this.confirmacionOrden.orden_venta_id, this.confirmacionOrden.fecha_retiro).subscribe(
          async json => {
            this.modal = 'oculto';
            let orden = await this.getOrdenById(this.confirmacionOrden.orden_venta_id);
            if (orden) {
              console.log(orden);
              this._pedidosService.updateContrasena(this.confirmacionOrden.orden_venta_id, orden.contrasena).subscribe(
                o => {
                  Swal.fire('Ok', 'Se ha confirmado fecha retiro', 'success');
                },
                err => {
                  if (!err.error) {
                    this.error('500 (Internal Server Error)');
                    return;
                  }
                  this.errores = err.error.errors;
                  console.error('Código del error desde el backend: ' + err.status);
                }
              );
            } else {
              Swal.fire('Atencion', 'No se pudo guardar contraseña', 'success');
            }
          },
          err => {
            if (!err.error) {
              this.modal = 'oculto';
              this.error('500 (Internal Server Error)');
              return;
            }
            this.errores = err.error.errors;
            console.error('Código del error desde el backend: ' + err.status);
          }
        );
      },
      err => {
        if (!err.error) {
          this.modal = 'oculto';
          this.error('500 (Internal Server Error)');
          return;
        }
        this.errores = err.error.errors;
        console.error('Código del error desde el backend: ' + err.status);
      }
    )

  }

  getOrdenById(orden_venta_ido) {
    let orden = this._abiServices.getOrdenById(orden_venta_ido).toPromise();
    return orden;
  }

  updateContrasena(orden_venta_id, contrasena) {
    let pedido = this._pedidosService.updateContrasena(orden_venta_id, contrasena).toPromise();
    return pedido;
  }
  updatePedidoFecha(orden_venta_id, fecha_retiro) {
    let pedido = this._pedidosService.confirmarFechaRetiro(orden_venta_id, fecha_retiro).toPromise();
    return pedido;
  }
  cerrarModal() {
    this.modal = 'oculto';
  }


  cambioTerminal(event) {
    for (let indice = 0; indice < this.motivosAnulacion.length; indice++) {           // tslint:disable-next-line:triple-equals
      if (this.motivosAnulacion[indice].codMotivoAnulacion == this.seleccionMotivo) {
        this.mAnulacion = this.motivosAnulacion[indice];
      }
    }
  }
  invalido(invalido) {
    this.toastr.error(invalido, 'Invalido', { timeOut: 2200 });
    Swal.fire('Atención', invalido, 'warning');
  }




  cambioSucursal(EVENTO) {
    this.codSucursal = EVENTO;
    this.codUsuario = 0;
    this.seleccionUsuario = 0;
    if (this.codSucursal > 0) {
      this.cargarUsuarios(this.codSucursal);
    }

  }
  cambioUsuario(id) {
    this._usuariosServices.getUsuario(id).subscribe(cargadorUsuario => {
      this.cargadorUsuario = cargadorUsuario;
    });
  }


  cargarSucursalPorId(codSuc) {
    this._sucursalesServices.getSucursalbyId(codSuc).subscribe(sucursal => {
      this.sucursales.push(sucursal);
      this.cargadorSucursal = sucursal;
    });
  }

  cargarUsuarios(codSucursal) {
    this._usuariosServices.traerUsuariosPorSucursal(codSucursal).subscribe(usuarios => {
      let auxus: Usuarios = {
        enabled: true,
        codUsuario: 0,
        nombrePersona: 'TODOS',
        codPersonaErp: '8554',
        username: 'todos@cavallaro.com.py',
        rol: null,
        codEmpresa: 1,
        sucursal: null,
        intentoFallido: 0,
        bloqueado: false,
        createdAt: null,
        modifiedAt: null,
        createdBy: 'todos@todos.com',
        modifiedBy: 'admin@admin.com',
        img: '',
      };

      this.usuarios = usuarios;
      this.usuarios.push(auxus);
      this.codUsuario = 0;
      this.seleccionUsuario = 0;

    });
  }

  async cargarSucursalById(cod) {
    let sucursal = this._sucursalesServices.getSucursalbyId(cod).toPromise();
    return sucursal;
  }

  // calcularTotalKgV(pedidoDetalle: PedidoDetalle[]): Promise<boolean> {
  //   return new Promise<boolean>(async (resolve) => {
  //     for await (const detalle of pedidoDetalle) {
  //       this.totalGs = this.totalGs + detalle.importeTotal;
  //       this.totalkg = this.totalkg + detalle.producto.peso;
  //     }
  //     resolve(true);
  //   });
  // }
  // sumarTotales(): Promise<Boolean> {
  //   return new Promise<Boolean>(async (resolve) => {
  //     this.totalGs = 0;
  //     this.totalkg = 0;
  //     for await (const pedido of this.pedidos) {
  //       if (pedido.anulado == false) {
  //         let respo = await this.traerModeloPorId(pedido.codPedido);
  //         let calculartotales = await this.calcularTotalKgV(respo.pedido.detalle);
  //       }
  //     }
  //     resolve(true);
  //   });
  // }
  async traerModeloPorId(cod) {
    let response = this._pedidosService.getById(cod).toPromise();
    return response;
  }
  async findTotal() {
    let response = this._pedidosService.findTotal(
      this.fechaInicio,
      this.fechafin,
      this.cargadorCliente,
      this.cargadorUsuario,
      (this.codSucursal) ? this.codSucursal : 0,
      null,
      this.tipoPedido,
      this.nroPedido
    ).toPromise();
    return response;
  }

}

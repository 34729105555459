<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!grupoDescuento.codGrupo" class="m-b-0 text-white">Crear Grupo</h4>
        <h4 *ngIf="grupoDescuento.codGrupo" class="m-b-0 text-white">Actualizar Grupo</h4>
    </div>
    <div class="card-body">

        <form #grupoDescuentoForm="ngForm">

          

       
            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Grupo <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="grupoDescuento.descripcion" (ngModelChange)="grupoDescuento.descripcion = toUpeCaseEvent($event)" name="descripcion" #descripcion="ngModel" required minlength="5">
                    <div class="alert alert-danger" *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 5 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="descuento" class="col-form-label col-sm-2">Descuento <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" min="0" class="form-control" [(ngModel)]="grupoDescuento.descuento" name="descuento" #descuento="ngModel" required>
                    <div class="alert alert-danger" *ngIf="descuento.invalid && (descuento.dirty || descuento.touched)">
                        <div *ngIf="!!descuento.errors.required">
                            descuento es requerido
                        </div>
                    </div>
                </div>
            </div>
 
            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/grupoDescuento']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!grupoDescuento.codGrupo else elseBlock" [disabled]="!grupoDescuentoForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!grupoDescuentoForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <div>
                <h3 class="card-title m-b-5 center"><span class="lstick"></span>Compras</h3>
            </div>
        </div>

        <form>
            <div class="form-group row">
                <div class="col-sm-1">
                    <button class="btn btn-success m-r-0" type="button" style="margin-top: 28%;"
                        (click)="abrirModalProveedor()">
                        <i class="fa fa-search"></i> Proveedor
                    </button>
                </div>
                <div class="col-sm-3">
                    <label for="docNro">Ruc</label>
                    <input type="text" class="form-control" id="docNro" name="nroDoc" placeholder="xxxxxxxx-x"
                        [(ngModel)]="proveedor.docNro">
                </div>
                <div class="col-sm-5">
                    <label for="razonSocial">Razon Social</label>
                    <input type="text" class="form-control" id="razonSocial" name="razonSocial" placeholder="Juan Perez"
                        [(ngModel)]="proveedor.razonSocial">
                </div>
                <div class="col-sm-3">
                    <label for="fechaCompra">Fecha:</label>
                    <input type="date" class="form-control" id="fechaCompra" name="fechaCompra"
                        [(ngModel)]="compra.fechaCompra">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                    <label for="telefono">Tel/Cel</label>
                    <input type="text" class="form-control" id="telefono" name="telefono" placeholder="021-258-255"
                        [(ngModel)]="proveedor.telefono">
                </div>
                <div class="col-sm-4">
                    <label for="direccion">Dirección</label>
                    <input type="text" class="form-control" id="direccion" name="direccion" placeholder="Avda principal"
                        [(ngModel)]="proveedor.direccion">
                </div>
                <div class="col-sm-4">
                    <label for="email">E-mail</label>
                    <input type="text" class="form-control" id="email" name="email" placeholder="py@empresa.com.py"
                        [(ngModel)]="proveedor.email">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-3">
                    <label for="timbrado">Timbrado</label>
                    <input type="text" class="form-control" id="timbrado" name="timbrado" placeholder="timbrado"
                        [(ngModel)]="compra.timbrado">
                </div>
                <div class="col-sm-3">
                    <label for="nroComprobante">Nº Comprobante</label>
                    <input type="text" class="form-control" id="nroComprobante" name="nroComprobante"
                        placeholder="xxx-xxx-xxxxxxxx" [(ngModel)]="compra.nroComprobante">
                </div>
                <div class="col-sm-3">
                    <label for="fechaInicio">Vigencia Inicio</label>
                    <input type="date" class="form-control" id="fechaInicio" name="fechaInicio"
                        [(ngModel)]="compra.inicioTimbrado">
                </div>
                <div class="col-sm-3">
                    <label for="fechaFin">Vigencia fin</label>
                    <input type="date" class="form-control" id="fechaFin" name="fechaFin"
                        [(ngModel)]="compra.finTimbrado">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6 form-group button-group">

                    <button class="btn    btn-info" type="button" (click)="guardar()">
                        <i class="fa fa-check"></i>Guardar
                    </button>
                    <button class="btn   btn-danger" type="button" (click)="ngOnInit()">
                        <i class="fa fa-times"></i>Limpiar
                    </button>

                </div>
                <div class="col-sm-6">

                </div>
            </div>

        </form>

    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <div>
                <h3 class="card-title m-b-5 center"><span class="lstick"></span>Detalles</h3>
            </div>
        </div>

        <table class="table table-sm table-bordered ">
            <thead>
                <tr>

                    <th style="width: 30%;">Producto</th>
                    <th style="width: 10%;">Unidad</th>
                    <th style="width: 20%;">Deposito</th>
                    <th style="width: 10%;">Precio</th>
                    <th style="width: 10%;">Cantidad</th>
                    <th style="width: 10%;">%Desc</th>
                    <th style="width: 10%;">Agregar</th>
                </tr>
                <tr>
                    <th style="width: 30%;">
                        <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto"
                            style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)">
                        </ng-select-producto>
                    </th>
                    <th style="width: 10%;">
                        <ng-select-unidad #searchProductos id="selectunidad" [cargadorUnidad]="cargadorUnidad"
                            style="width: 100%" name="unidad" (retornoObjeto)="seleccionarUnidad($event)">
                        </ng-select-unidad>
                    </th>
                    <th style="width: 20%;">
                        <ng-select-deposito #searchProductos id="selectdeposito" [cargadorDeposito]="cargadorDeposito"
                            style="width: 100%" name="deposito" (retornoObjeto)="seleccionarDeposito($event)">
                        </ng-select-deposito>
                    </th>
                    <th style="width: 10%;">
                        <input type="number" class="form-control" name="precio" [(ngModel)]="importePrecio">
                    </th>
                    <th style="width: 10%;">
                        <input type="number" class="form-control" name="cantidad" [(ngModel)]="cantidad">
                    </th>
                    <th style="width: 10%;">
                        <input type="number" class="form-control" name="porcDescuento" [(ngModel)]="porcDescuento">
                    </th>
                    <th style="width: 10%;">
                        <button class="btn    btn-success" type="button" (click)="agregarDetalle()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </th>
                </tr>
            </thead>



        </table>
        <perfect-scrollbar class="scroll-container"
            style="position: relative; max-width: 100%; max-height: 4500px;height: 400px;" [scrollIndicators]="true">
            <div class="scrollable-content table-responsive">
                <table class="table table-sm table-bordered ">
                    <thead>
                        <tr>
                            <th>###</th>
                            <th>Producto</th>
                            <th>Unidad</th>
                            <th>Deposito</th>
                            <th>%iva</th>
                            <th>precio</th>
                            <th>cantidad</th>
                            <th>%descuento</th>
                            <th>I.descuento</th>
                            <th>I.IVA5</th>
                            <th>I.IVA10</th>
                            <th>I.IVA Ex</th>
                            <th>I.Sub</th>
                            <th>I. total</th>
                            <th>###</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detail of compra.detalle;let i =index">
                            <td>{{i+1}}</td>
                            <td>
                                {{detail.producto.concatCodErpNombre}}
                            </td>
                            <td>
                                {{detail.unidadMedida.codUnidadErp}}
                            </td>
                            <td>
                                {{detail.deposito.nombreDeposito}}
                            </td>
                            <td>
                                {{detail.porcIva  | number}} %
                            </td>
                            <td>
                                {{detail.importePrecio  | number}}
                            </td>
                            <td>
                                {{detail.cantidad  | number}}
                            </td>
                            <td>
                                {{detail.porcDescuento  | number}}
                            </td>
                            <td>
                                {{detail.importeDescuento  | number}}
                            </td>
                            <td>
                                {{detail.importeIva5  | number}}
                            </td>
                            <td>
                                {{detail.importeIva10  | number}}
                            </td>
                            <td>
                                {{detail.importeIvaExenta  | number}}
                            </td>
                            <td>
                                {{detail.subTotal  | number}}
                            </td>
                            <td>
                                {{detail.importeTotal  | number}}
                            </td>
                            <td>
                                <button class="btn    btn-danger" type="button" (click)=" quitarDetalle(i)">
                                    <i class="fa fa-times"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </perfect-scrollbar>
        <table class="table table-sm table-bordered ">
            <tfoot class="alert-info">
                <tr>
                    <td> Total Descuento: </td>
                    <td> {{compra.importeDescuento  | number}} </td>
                </tr>
                <tr>
                    <td> Total Importe Iva 5%: </td>
                    <td> {{compra.importeIva5  | number}} </td>
                </tr>
                <tr>
                    <td> Total Importe Iva 10%: </td>
                    <td> {{compra.importeIva10  | number}} </td>
                </tr>
                <tr>
                    <td> Total Importe Iva Exenta: </td>
                    <td> {{compra.importeIvaExenta  | number}} </td>
                </tr>
                <tr>
                    <td> Total Importe sub total: </td>
                    <td> {{compra.subTotal  | number}} </td>
                </tr>
                <tr>
                    <td> Total Importe total: </td>
                    <td>{{compra.importeTotal  | number}} </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
    <!--  ===========================================================Modal Vendedor Det====================================== -->
    <div class="fondo-negro-t animated fadeIn" [ngClass]="modalProveedor">
        <div class="modal" style="display: block;" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header label-themecolor">
                        <h4 class="text-white">Seleccionar Proveedor</h4>
                        <button (click)="cancelarModalProveedor()" type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="modal-body mx-3">
                            <div class="md-form mb-5">
                                <label for="p" class="col-form-label ">Proveedor</label>

                                <ng-select-proveedor #searchVendedor id="p" [cargadorProveedor]="cargadorProveedor"
                                    name="proveedor" (retornoObjeto)="seleccionarProveedor($event)">
                                </ng-select-proveedor>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group row button-group">
                                <div class="col-sm-12 align-self-center" style=" text-align: center;" aling="center">
                                    <button class="btn btn-secondary  " (click)="confirmarProveedor()" type="button ">
                                        <i class="fa fa-save"></i>
                                        Guardar </button>
                                    <button class="btn btn-secondary  " (click)="cancelarModalProveedor()" type="button"
                                        data-dismiss="modal">
                                        <i class="fa  fa-arrow-circle-left"></i> Atrás
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!formaVenta.codFormaVenta" class="m-b-0 text-white">Crear forma venta</h4>
        <h4 *ngIf="formaVenta.codFormaVenta" class="m-b-0 text-white">Actualizar forma venta</h4>
    </div>
    <div class="card-body">

        <form #formaVentaForm="ngForm">

            <div class="form-group row">
                <label for="cantDias" class="col-form-label col-sm-2">Cantidad días <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" min="0" class="form-control" [(ngModel)]="formaVenta.cantDias" name="cantDias" #cantDias="ngModel" required>
                    <div class="alert alert-danger" *ngIf="cantDias.invalid && (cantDias.dirty || cantDias.touched)">
                        <div *ngIf="!!cantDias.errors.required">
                            cantDias es requerido
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="esContado" class="col-form-label col-sm-2">Es contado  <span style="color: red">(*)</span> </label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="esContado" id="selectEsContado" [(ngModel)]="formaVenta.esContado" required #esContado="ngModel">
                <option value="true">SI</option>
                <option value="false" >NO</option>
            </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Forma venta <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="formaVenta.descripcion" (ngModelChange)="formaVenta.descripcion = toUpeCaseEvent($event)" name="descripcion" #descripcion="ngModel" required minlength="5">
                    <div class="alert alert-danger" *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 5 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="codFormaVentaErp" class="col-form-label col-sm-2">Cod Ref. <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="formaVenta.codFormaVentaErp" (ngModelChange)="formaVenta.codFormaVentaErp =  toUpeCaseEvent($event)" name="codFormaVentaErp" #codFormaVentaErp="ngModel" required minlength="1">
                    <div class="alert alert-danger" *ngIf="codFormaVentaErp.invalid && (codFormaVentaErp.dirty || codFormaVentaErp.touched)">
                        <div *ngIf="!!codFormaVentaErp.errors.required">
                            codFormaVentaErp es requerido
                        </div>
                        <div *ngIf="!!codFormaVentaErp.errors.minlength">
                            codFormaVentaErp debe tener al menos 1 caracter
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/formaVenta']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!formaVenta.codFormaVenta else elseBlock" [disabled]="!formaVentaForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!formaVentaForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
// test
export const BASE_URL = 'https://mposdev.mobile.com.py/mpos/rest/';
// local
//export const BASE_URL = 'http://localhost:8080/rest/';
// produccion
//export const BASE_URL = 'https://pos.cavallaro.com.py/mpos/rest/';
// mobile
//export const BASE_URL ="https://mpos.mobile.com.py/mpos/rest/";
// TOKEN ABI
export const ABI_PATH = 'https://abi.softec.com.py/backoffice/api/v1/fab';
//export const ABI_PATH = 'https://app.abi.com.py/backoffice/api/v1/fab';
export const ABI_TOKEN = 'c97f2cac94c143d4907cd44c1ffe33836e0f6c3f';
//Driver de balanza
export const driver_url = 'http://localhost:3000/api';

<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}

    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!tipoMedioPago.codTipoMedioPago" class="m-b-0 text-white">Crear Tipo Medio Pago</h4>
        <h4 *ngIf="tipoMedioPago.codTipoMedioPago" class="m-b-0 text-white">Actualizar Tipo Medio Pago</h4>
    </div>
    <div class="card-body">

        <form #tipoMedioPagoForm="ngForm">

            <div class="form-group row">
                <label for="codTipoMedioPagoErp" class="col-form-label col-sm-2">Codigo Erp <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="tipoMedioPago.codTipoMedioPagoErp"
                        (ngModelChange)="tipoMedioPago.codTipoMedioPagoErp = toUpeCaseEvent($event)"
                        name="codTipoMedioPagoErp" #codTipoMedioPagoErp="ngModel" required minlength="1">
                    <div class="alert alert-danger"
                        *ngIf="codTipoMedioPagoErp.invalid && (codTipoMedioPagoErp.dirty || codTipoMedioPagoErp.touched)">
                        <div *ngIf="!!codTipoMedioPagoErp.errors.required">
                            codTipoMedioPagoErp es requerido
                        </div>
                        <div *ngIf="!!codTipoMedioPagoErp.errors.minlength">
                            codTipoMedioPagoErp debe tener al menos 1 caracter
                        </div>
                    </div>

                </div>
            </div>


            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Tipo Medio Pago<span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="tipoMedioPago.descripcion"
                        (ngModelChange)="tipoMedioPago.descripcion =  toUpeCaseEvent($event)" name="descripcion"
                        #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 4 caracter
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group row">
                <label for="selectMedioPago" class="col-form-label col-sm-2">Medio Pago <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="selectMedioPago" class="custom-select" style="width: 100%; height:36px;" tabindex="-1"
                        aria-hidden="true" [(ngModel)]="seleccionMedioPago" #selectMedioPago="ngModel"
                        name="selectMedioPago" (ngModelChange)="cambioMedioPago($event)">
                        <option *ngFor="let m of medios" [value]="m.codMedioPago">
                            {{m.descripcion}}
                        </option>
                    </select>
                </div>
            </div>





            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/tipoMedioPago']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                        (click)='create()' [disabled]="!tipoMedioPagoForm.form.valid"
                        *ngIf="!tipoMedioPago.codTipoMedioPago else elseBlock"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!tipoMedioPagoForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>
export class ABI_Punto_Retiro {
    //  ? significa opcional
    public id: number;
    public nombre: string;
    public calle_principal: string;
    public calle_secundaria: string;
    public numero: string;
    public referencia: string;
    public ubicacion: string;
    public ciudad: string;
        constructor(
            // public id: number,
            // public nombre: string,
            // public calle_principal: string,
            // public calle_secundaria: string,
            // public numero: string,
            // public referencia: string,
            // public ubicacion: string,
            // public ciudad: string,
        ) { }
    }
    
<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!tipoDeposito.codTipoDeposito" class="m-b-0 text-white">Crear tipo Deposito</h4>
        <h4 *ngIf="tipoDeposito.codTipoDeposito" class="m-b-0 text-white">Actualizar tipo Deposito</h4>
    </div>
    <div class="card-body">

        <form #tipoDepositoForm="ngForm">


            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">TipoDeposito <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="tipoDeposito.descripcion" (ngModelChange)="tipoDeposito.descripcion =  toUpeCaseEvent($event)" name="descripcion" #descripcion="ngModel" required minlength="5">
                    <div class="alert alert-danger" *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 5 caracteres
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/tipoDeposito']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!tipoDeposito.codTipoDeposito else elseBlock" [disabled]="!tipoDepositoForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!tipoDepositoForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>

        </form>

    </div>
</div>
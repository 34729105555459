<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!deposito.codDeposito" class="m-b-0 text-white">Crear deposito</h4>
        <h4 *ngIf="deposito.codDeposito" class="m-b-0 text-white">Actualizar actualizar</h4>
    </div>
    <div class="card-body">

        <form #depositoForm="ngForm">




            <div class="form-group row">
                <label for="codDepositoErp" class="col-form-label col-sm-2">Codigo Erp <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="deposito.codDepositoErp" (ngModelChange)="deposito.codDepositoErp =  toUpeCaseEvent($event)" name="codDepositoErp" #codDepositoErp="ngModel" required minlength="1">
                    <div class="alert alert-danger" *ngIf="codDepositoErp.invalid && (codDepositoErp.dirty || codDepositoErp.touched)">
                        <div *ngIf="!!codDepositoErp?.errors.required">
                            codDepositoErp es requerido
                        </div>
                        <div *ngIf="!!codDepositoErp?.errors.minlength">
                            codDepositoErp debe tener al menos 1 caracteres
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label for="nombreDeposito" class="col-form-label col-sm-2">Deposito <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="deposito.nombreDeposito" (ngModelChange)="deposito.nombreDeposito =  toUpeCaseEvent($event)" name="nombreDeposito" #nombreDeposito="ngModel" required minlength="4">
                    <div class="alert alert-danger" *ngIf="nombreDeposito.invalid && (nombreDeposito.dirty || nombreDeposito.touched)">
                        <div *ngIf="!!nombreDeposito?.errors.required">
                            nombreDeposito es requerido
                        </div>
                        <div *ngIf="!!nombreDeposito?.errors.minlength">
                            nombreDeposito debe tener al menos 4 caracteres
                        </div>

                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label for="tipoDeposito" class="col-form-label col-sm-2">Tipo <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="tipos" name="tipoDeposito" notFoundText="No se ha encontrado deposito" placeholder="Seleccione tipo deposito" [(ngModel)]="deposito.tipoDeposito" bindLabel="descripcion" (change)="seleccionarTipoDeposito($event)">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <b [ngOptionHighlight]="search">{{item.descripcion}}</b>
                        </ng-template>
                    </ng-select>
                </div>
            </div>


            <div class="form-group row">
                <label for="tipoVenta" class="col-form-label col-sm-2">tipoVenta  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="tipoVenta" id="selectTipoVenta" [(ngModel)]="deposito.tipoVenta" #predeterminado="ngModel">
                    <option value="true">SI</option>
                        <option value="false" >NO</option>
                  </select>
                </div>
            </div>



            <div class="form-group row">
                <label for="sucursal" class="col-form-label col-sm-2">Sucursal <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select-sucursal #searchSucursales id="ngbdtypeaheadSucursales" [cargadorSucursal]="cargadorSucursal" style="width: 100%" name="input-typeahead-cl" (retornoObjeto)="seleccionarSucursal($event)"></ng-select-sucursal>
                </div>
            </div>



            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/depositos']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!deposito.codDeposito else elseBlock" [disabled]="!depositoForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!depositoForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>

export class Influencer {
        //  ? significa opcional
        public codInfluencer: number;
        public codEmpresa: number;
        public activo: boolean;
        public influencer: string;
        public cupon: string;
        public descuento: number; 
        public fechaDesde: Date;
        public fechaHasta: Date;
        public cantSeguidores: number;
        public cantValidez: number;
        constructor() { }

} 
<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
        

                <li *ngFor="let menu of _sidebar.menu">
                    <a *ngIf=" permitirAcceso(_loginService.user.authorities[0],menu.excluir)"
                        class="has-arrow waves-effect waves-dark" aria-expanded="false">
                        <i [class]="menu.icono"></i>
                        <span class="hide-menu"> {{ menu.titulo }}
                            <span class="label label-rouded label-themecolor pull-right"> {{ menu.submenu.length }}
                            </span>
                        </span>
                    </a>

                     <ul aria-expanded="false" class="collapse">

                        <li *ngFor="let submenu of menu.submenu">
                             <a *ngIf="permitirAcceso(_loginService.user.authorities[0],submenu.excluir)"
                                routerLinkActive="active" [routerLink]="[ submenu.url ]"> {{ submenu.titulo }} </a>
                        </li>
 
                    </ul>
                </li>


            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<ul class="alert alert-danger" *ngIf="errores?.length > 0">
  <li *ngFor="let err of errores">
    {{ err.field +" "+ err.defaultMessage}}
  </li>
</ul>

<div class="card ">
  <div class="card-header label-themecolor">
    <h4 *ngIf="!sucursal.codSucursal" class="m-b-0 text-white">Crear sucursal</h4>
    <h4 *ngIf="sucursal.codSucursal" class="m-b-0 text-white">Actualizar sucursal</h4>
  </div>
  <div class="card-body">

    <form #sucursalForm="ngForm">
      <div class="form-group row">
        <div class="col-md-6 ">
          <div class="form-group row">
            <label for="codSucursalErp" class="col-form-label col-sm-2">Codigo Erp <span
                style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" [(ngModel)]="sucursal.codSucursalErp"
                (ngModelChange)="sucursal.codSucursalErp =  toUpeCaseEvent($event)" name="codSucursalErp"
                #codSucursalErp="ngModel" required minlength="1">
              <div class="alert alert-danger"
                *ngIf="codSucursalErp.invalid && (codSucursalErp.dirty || codSucursalErp.touched)">
                <div *ngIf="!!codSucursalErp.errors.required">
                  codSucursalErp es requerido
                </div>
                <div *ngIf="!!codSucursalErp.errors.minlength">
                  codSucursalErp debe tener al menos 1 caracteres
                </div>
              </div>
            </div>
          </div>


          <div class="form-group row">
            <label for="nombreSucursal" class="col-form-label col-sm-2">Sucursal <span
                style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" [(ngModel)]="sucursal.nombreSucursal"
                (ngModelChange)="sucursal.nombreSucursal = toUpeCaseEvent($event)" name="nombreSucursal"
                #nombreSucursal="ngModel" required minlength="3">
              <div class="alert alert-danger"
                *ngIf="nombreSucursal.invalid && (nombreSucursal.dirty || nombreSucursal.touched)">
                <div *ngIf="!!nombreSucursal.errors.required">
                  nombreSucursal es requerido
                </div>
                <div *ngIf="!!nombreSucursal.errors.minlength">
                  nombreSucursal debe tener al menos 3 caracteres
                </div>

              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="envioposventa" class="col-form-label col-sm-2">Envio msg <span
                style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <select class=" custom-select  form-control" name="tipoDoc" id="envioposventa"
                [(ngModel)]="sucursal.envioposventa" #envioposventa="ngModel">
                <!-- <option selected>Seleccionar...</option> -->
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>

            </div>
          </div>
          <div class="form-group row">
            <label for="mensaje" class="col-form-label col-sm-2">Mensaje </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" [(ngModel)]="sucursal.mensaje" name="mensaje"
                #mensaje="ngModel" required minlength="4">                      </textarea>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <agm-map name="mapas" id="mapas" (mapClick)="agregarMarcador( $event )" [latitude]="lat" [longitude]="lng"
                [zoom]="10">
                <agm-marker [latitude]="sucursal.latitud" [longitude]="sucursal.longitud">
                  <agm-info-window>
                    <strong>{{ sucursal.nombreSucursal }}</strong>
                    <p>{{ sucursal.direccion }}</p>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </div>
          </div>

          <div class="form-group row button-group">
            <div class="col-sm-6">
              <button type="button" [routerLink]="['/sucursales']" class="btn btn-danger waves-effect waves-light"><i
                  class="fa  fa-arrow-circle-left"></i>
                Atrás</button>
              <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                *ngIf="!sucursal.codSucursal else elseBlock" [disabled]="!sucursalForm.form.valid"><i
                  class="fa   fa-floppy-o"></i>Crear</button>
              <ng-template #elseBlock>
                <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()'
                  [disabled]="!sucursalForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
              </ng-template>
            </div>
          </div>



        </div>
        <div class="col-md-6 ">

          <div class="form-group row">
            <label for="direccion" class="col-form-label col-sm-2">Direccion <span style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" [(ngModel)]="sucursal.direccion"
                (ngModelChange)="sucursal.direccion =  toUpeCaseEvent($event)" name="direccion" #direccion="ngModel"
                required minlength="5">
              <div class="alert alert-danger" *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)">
                <div *ngIf="!!direccion.errors.required">
                  Direccion es requerido
                </div>
                <div *ngIf="!!direccion.errors.minlength">
                  Direccion debe tener al menos 5 caracteres
                </div>
              </div>
            </div>
          </div>


          <div class="form-group row">
            <label for="telefono" class="col-form-label col-sm-2">Telefono <span style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" [(ngModel)]="sucursal.telefono"
                (ngModelChange)="sucursal.telefono =  toUpeCaseEvent($event)" name="telefono" #telefono="ngModel"
                required minlength="6">
              <div class="alert alert-danger" *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)">
                <div *ngIf="!!telefono.errors.required">
                  telefono es requerido
                </div>
                <div *ngIf="!!telefono.errors.minlength">
                  telefono debe tener al menos 6 caracteres
                </div>

              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="email" class="col-form-label col-sm-2">Email <span style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <input type="email"
                pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                class="form-control" [(ngModel)]="sucursal.email" name="email" #email="ngModel" required>
              <div class="alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                <div *ngIf="!!email.errors.required">
                  Email es requerido
                </div>
                <div *ngIf="!!email.errors">
                  Email debe tener un formato válido
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="centro" class="col-form-label col-sm-2">Centro </label>
            <div class="col-sm-6">
              <input type="text" class="form-control" [(ngModel)]="sucursal.centro"
                (ngModelChange)="sucursal.centro =  toUpeCaseEvent($event)" name="centro" #centro="ngModel">

            </div>
          </div>


          <div class="form-group row">
            <label for="modoVendedor" class="col-form-label col-sm-2">Modo vendedor <span
                style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <select class=" custom-select  form-control" name="modoVendedor" id="modoVendedor"
                [(ngModel)]="sucursal.modoVendedor" #modoVendedor="ngModel">
                <option value="GENERAL">GENERAL</option>
                <option value="DETALLE">DETALLE</option>
              </select>
            </div>
          </div>


          <div class="form-group row">
            <label for="principal" class="col-form-label col-sm-2">Principal <span style="color: red">(*)</span></label>
            <div class="col-sm-6">
              <select class=" custom-select  form-control" name="tipoDoc" id="selectprincipal"
                [(ngModel)]="sucursal.principal" #principal="ngModel">
                <!-- <option selected>Seleccionar...</option> -->
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>

            </div>
          </div>

          <div class="form-group row">
            <label for="latitud" class="col-form-label col-sm-2">Latitud</label>
            <div class="col-sm-6">
              <input type="number" class="form-control" id="latitud" name="latitud" [(ngModel)]="sucursal.latitud"
                required>
            </div>
          </div>

          <div class="form-group row">
            <label for="longitud" class="col-form-label col-sm-2">Longitud</label>
            <div class="col-sm-6">
              <input type="number" class="form-control" id="longitud" name="longitud" [(ngModel)]="sucursal.longitud"
                required>
            </div>
          </div>


        </div>
      </div>




    </form>

  </div>
</div>

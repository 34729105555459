<div class="card ">

    <div class="card-header label-themecolor">
        <h4 *ngIf="!premio.codPremio" class="m-b-0 text-white">Crear premio</h4>
        <h4 *ngIf="premio.codPremio" class="m-b-0 text-white">Actualizar premio</h4>
    </div>
    <div class="card-body">
        <form #premioForm="ngForm">

            <div class="col-lg-2 border-dark">
                <div class="card">
                    <div class="card border-dark mb-3" style="max-width: 18rem;">
                        <img *ngIf="!imagenTemp && !premio.img" [src]="sinImagen" style="width:242px;height:200px;">
                        <img *ngIf="!imagenTemp && premio.img" [attr.src]="premio.img | imagen:'premios' | async"
                            style="width:242px;height:200px;">
                        <img *ngIf="imagenTemp" [src]="imagenTemp" style="width:242px;height:200px;">
                        <div class="caption">
                            <input id="uploadedfile" (change)="seleccionImage($event.target.files[0])" type="file">
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Descripcion <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="premio.descripcion"
                        (ngModelChange)="premio.descripcion =  toUpeCaseEvent($event)" name="descripcion"
                        #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="codBarra" class="col-form-label col-sm-2">Codigo de barra <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="premio.codBarra"
                        (ngModelChange)="premio.codBarra =  toUpeCaseEvent($event)" name="codBarra"
                        #codBarra="ngModel" required minlength="4">
                    <div class="alert alert-danger" *ngIf="codBarra.invalid && (codBarra.dirty || codBarra.touched)">
                        <div *ngIf="!!codBarra.errors.required">
                            Codigo de barra es requerido
                        </div>
                        <div *ngIf="!!codBarra.errors.minlength">
                            Codigo de barra debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="codPremioErp" class="col-form-label col-sm-2">Codigo Erp <span
                        style="color: red">(*)</span> </label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="premio.codPremioErp"
                        (ngModelChange)="premio.codPremioErp = toUpeCaseEvent($event)" name="codPremioErp"
                        required #codPremioErp="ngModel">
                    <div class="alert alert-danger"
                        *ngIf="codPremioErp.invalid && (codPremioErp.dirty || codPremioErp.touched)">
                        <div *ngIf="!!codPremioErp.errors.required">
                            codPremioErp es requerido
                        </div>
                        <div *ngIf="!!codPremioErp.errors.minlength">
                            codPremioErp debe tener al menos 1 caracter
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="activo" class="col-form-label col-sm-2">Activo <span style="color: red">(*)</span> </label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="premio.activo" name="activo" id="activo"
                    #activo="ngModel" [clearable]="false">
                    <ng-option [value]="true">SI</ng-option>
                    <ng-option [value]="false">NO</ng-option>
                    </ng-select>
                   
                </div>
            </div>
            <div class="form-group row">
                <label for="inventariable" class="col-form-label col-sm-2">Inventariable <span style="color: red">(*)</span> </label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="premio.inventariable" name="activo" id="inventariable"
                    #inventariable="ngModel" [clearable]="false">
                    <ng-option [value]="true">SI</ng-option>
                    <ng-option [value]="false">NO</ng-option>
                    </ng-select>
                   
                </div>
            </div>

         
            <div class="form-group row">
                <label for="puntos" class="col-form-label col-sm-2">Puntos <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="premio.puntos" name="puntos" #puntos="ngModel">
                </div>
            </div>

            
            <div class="form-group row">
                <label for="descuento" class="col-form-label col-sm-2">Descuento <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="premio.descuento" name="descuento" #descuento="ngModel">
                </div>
            </div>
 
            <div class="form-group row">
                <label for="obs" class="col-form-label col-sm-2">Obs </label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="premio.obs"
                        (ngModelChange)="premio.obs =  toUpeCaseEvent($event)" name="obs" #obs="ngModel">
                </div>
            </div>
 
            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/premios']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                        *ngIf="!premio.codPremio else elseBlock" [disabled]="!premioForm.form.valid"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!premioForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>

        </form>

    </div>
</div>
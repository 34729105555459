<div class="card border-primary mb-3">
    <div class="card-header label-themecolor">
        <h4 class="m-b-0 text-white">Listado de influencers( {{ influencers?.length}})</h4>
    </div>
    <div class="card-body text-dark">
        <div class="row form-group">
            <div class="col-3">
                <button class="btn btn-rounded   btn-success" type="button" (click)='mostrarModalInfluencer()'>
                    <i class="fa fa-plus"></i>
                    Nuevo
                </button>
            </div>
            <div class="col-3"></div>
        </div>
        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-6 col-md-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor</span>
                </div>
            </div>
        </div>
        <div *ngIf="influencers?.length == 0" class="alert alert-info">
            No se han encontrado registros ...
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-striped" *ngIf="influencers?.length>0">
                <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>influencer</th>
                        <th>cupon</th>
                        <th>descuento</th>
                        <th>desde</th>
                        <th>hasta</th>
                        <th>seguidores</th>
                        <th>cantValidez</th>
                        <th>Activo</th>
                        <th>Editar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let influencer of influencers | slice: (currentPage * pageSize) - pageSize :currentPage * pageSize">


                        <td>{{ influencer.codInfluencer }}</td>
                        <td>{{ influencer.influencer }}</td>
                        <td>{{ influencer.cupon }}</td>
                        <td>{{ influencer.descuento }}</td>
                        <td>{{ influencer.fechaDesde }}</td>
                        <td>{{ influencer.fechaHasta }}</td>
                        <td>{{ influencer.cantSeguidores }}</td>
                        <td>{{ influencer.cantValidez }}</td>
                        <td *ngIf="influencer.activo === true">Si</td>
                        <td *ngIf="influencer.activo === false">NO</td>



                        <td>
                            <button class="btn btn-info btn-circle " (click)='editarInfluencer(influencer)'
                                type="button">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container" *ngIf="influencers?.length > 0">
            <ngb-pagination class="d-flex justify-content-center" [size]="tamanhoPag"
                [collectionSize]="influencers.length" [(page)]="currentPage" [maxSize]="4" [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                <ng-template ngbPaginationNext>&raquo;</ng-template>
                <ng-template ngbPaginationFirst>Primera</ng-template>
                <ng-template ngbPaginationLast>Última</ng-template>
            </ngb-pagination>
        </div>

    </div>
    <!--  ==================================================Influencer=========================================================== -->
    <div class="fondo-negro animated fadeIn" [ngClass]="modalInfluencer">
        <div class="modal" style="display: block;" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header label-themecolor">
                        <h4 class="text-white ">Influencer-Cupon</h4>
                        <button (click)="ocultarModalInfluencer()" type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body " style=" text-align: center;">
                        <form #influencerForm="ngForm">

                            <div class="form-group row">
                                <label for="activo" class="col-form-label col-sm-4">Activo <span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <select class=" custom-select  form-control" name="activo" id="selectActivo"
                                        [(ngModel)]="influencer.activo" #activo="ngModel">
                                        <option value="true">SI</option>
                                        <option value="false">NO</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="influencerName" class="col-form-label col-sm-4">Influencer<span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" [(ngModel)]="influencer.influencer"
                                        name="influencerName" #influencerName="ngModel">

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="cupon" class="col-form-label col-sm-4">Codigo Cupon<span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" [(ngModel)]="influencer.cupon" name="cupon"
                                        #cupon="ngModel">

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="descuento" class="col-form-label col-sm-4">Descuento<span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" [(ngModel)]="influencer.descuento"
                                        name="descuento" #descuento="ngModel">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="cantValidez" class="col-form-label col-sm-4">Cant validez<span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control" [(ngModel)]="influencer.cantValidez"
                                        name="cantValidez" #cantValidez="ngModel">
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="inicioTimbrado" class="col-form-label col-sm-4">Fecha inicio <span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="date" class="form-control" [(ngModel)]="influencer.fechaDesde"
                                        name="inicioTimbrado" #inicioTimbrado="ngModel">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="finTimbrado" class="col-form-label col-sm-4">Fecha fin <span
                                        style="color: red">(*)</span></label>
                                <div class="col-sm-6">
                                    <input type="date" class="form-control" [(ngModel)]="influencer.fechaHasta"
                                        name="finTimbrado" #finTimbrado="ngModel">

                                </div>
                            </div>










                            <div class="form-group row button-group">
                                <div class="col-sm-6">
                                    <button type="button" (click)='ocultarModalInfluencer( )'
                                        class="btn btn-danger waves-effect waves-light"><i
                                            class="fa  fa-arrow-circle-left"></i>
                                        Atrás</button>
                                    <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                                        (click)='create()' [disabled]="!influencerForm.form.valid"
                                        *ngIf="!influencer.codInfluencer else elseBlock"><i
                                            class="fa   fa-floppy-o"></i>Crear</button>
                                    <ng-template #elseBlock>
                                        <button class="btn btn-info waves-effect waves-light" type="button"
                                            role="button" (click)='editar( )' [disabled]="!influencerForm.form.valid"><i
                                                class="fa   fa-floppy-o"></i>Actualizar</button>
                                    </ng-template>
                                </div>
                            </div>
                        </form>

                        <div class="modal-footer">

                            <button (click)="ocultarModalInfluencer()" type="button" class="btn btn-secondary "
                                data-dismiss="modal">cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
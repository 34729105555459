<!-- <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.nombrePersona" [term]="t"></ngb-highlight>
</ng-template>
<div class="form-group">

    <div class="form-group input-group">

        <input id="typeahead-usuarios" [class.is-invalid]="searchFailed" (selectItem)="selectedItem($event.item)" [inputFormatter]="formatter" (ngModel)="model" [resultTemplate]="rt" (focus)="onFocus($event)" [resultTemplate]="rt" type="text" class="form-control form-control-sm"
            [class.is-invalid]="searchFailed" [(ngModel)]="cargadorUsuario" [ngbTypeahead]="search" placeholder="Ingrese Usuario ..." />
        <span class="input-group-btn">
            <button class="btn btn-info  "   type="button" style="height: 31px" (click)="limpiar()" ><i class="fa fa-trash fa-xs"></i>
            </button>
        </span>
    </div>
 -->
<ng-select [items]="usuarios" (search)="onSearch($event)" placeholder="Seleccione usuario" (change)="selectedItem($event)" notFoundText="No se ha encontrado usuario" bindLabel="nombrePersona" [(ngModel)]="cargadorUsuario">
    <!--    <ng-template ng-option-tmp let-item="item">
        {{item.nombrePersona}} <br/>
        <small>{{item.username}}</small>
    </ng-template> -->
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <b [ngOptionHighlight]="search">{{item.username}}</b>

    </ng-template>
</ng-select>
<!-- <span *ngIf="searching">Buscando...</span>
<div class="invalid-feedback" *ngIf="searchFailed">No se ha encontrado usuario</div> -->
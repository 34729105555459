<div class="card border-primary mb-3">
    <div class="card-header label-themecolor">
        <h4 class="m-b-0 text-white">HistorialPuntos( {{ historialPuntos?.length}})</h4>
    </div>
    <div class="card-body text-dark">
        <div class="row form-group">
            <div class="col-3">
               
            </div>
            <div class="col-9">
                <div class="row col-12 form-group">
                    <div class="col-sm-6">
                        <label for="cliente" class="col-form-label"> Cliente:</label>
                        <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes" [cargadorCliente]="cargadorCliente"
                          style="width: 100%" name="cliente" (retornoObjeto)="seleccionarCliente($event)"></ng-select-clientes>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="col-sm-6">
                        <label for="fechaInicio" class="col-form-label"> Fecha Desde:</label>
                        <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechaInicio"
                          name="fechaInicio" />
                    </div>
                </div>
                <div class=" form-group button-group col-6">
                    <button class="btn btn-themecolor waves-effect waves-light" (click)='buscar()' type="button">
                        <i class="fa fa-search"></i>
                        Buscar
                    </button>
                    <button class="btn btn-secondary waves-effect waves-light" (click)='ngOnInit()' type="button">
                        <i class="fa fa-close"></i>
                        Cancelar
                    </button>
                </div>
            </div>


            <div class="col-3"></div>
        </div>
        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor</span>
                </div>
            </div>
        </div>

        <div *ngIf="historialPuntos?.length == 0" class="alert alert-info">
            No se han encontrado registros ...
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-striped" *ngIf="historialPuntos?.length>0">
                <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>Cliente </th>
                        <th>Puntos</th>
                        <th>Nro Comprobante</th>
                        <th>U. importe</th>
                        <th>Anulado</th>
                        <th>F.Modificacion</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let h of historialPuntos | slice: (currentPage * pageSize) - pageSize :currentPage * pageSize">
                        <td>{{ h.codigo }}</td>
                        <td>{{ h.cliente.razonSocial }}</td>
                        <td>{{ h.puntos | number }}</td>
                        <td>{{ h.nroComprobante }}</td>
                        <td>{{ h.ultimoImporte | number }}</td>
                        <td *ngIf = "h.ultimoImporte >= 0">NO </td>
                        <td *ngIf = "h.ultimoImporte < 0"> SI</td>
                        <td>{{ h.fechaModificacion | date:'dd/MM/yyyy, h:mm a' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container" *ngIf="historialPuntos?.length > 0">
            <ngb-pagination class="d-flex justify-content-center" [size]="tamanhoPag" [collectionSize]="historialPuntos.length" [(page)]="currentPage" [maxSize]="4" [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                <ng-template ngbPaginationNext>&raquo;</ng-template>
                <ng-template ngbPaginationFirst>Primera</ng-template>
                <ng-template ngbPaginationLast>Última</ng-template>
            </ngb-pagination>
        </div>

    </div>
</div>
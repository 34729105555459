<div class="row">
    <div class="col-lg-8">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <span>
                            <h3 class="card-title m-b-5 center">
                                <span class="lstick"></span>Listado de pedidos
                            </h3>
                        </span>
                    </div>
                </div>
                <div class="container py-5">

                    <form #listaPedidosForm="ngForm">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="fechaInicio" class="col-form-label"> F.Desde:</label>
                                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechaInicio"
                                    name="fechaInicio">
                            </div>
                            <div class="col-sm-6">
                                <label for="fechafin" class="col-form-label"> Fecha Hasta:</label>
                                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechafin"
                                    name="fechafin" />
                            </div>
                        </div>

                        <!-- =========== Para Role Adm ============== -->
                        <div class="form-group row" *ngIf="rol != 'ROLE_CAJERO'">
                            <div class="col-sm-6">
                                <label for="sucursal" class="col-form-label">Sucursal:</label>
                                <ng-select-sucursal #searchSucursales id="ngbdtypeaheadSucursales"
                                    [cargadorSucursal]="cargadorSucursal" style="width: 100%" name="sucursal"
                                    (retornoObjeto)="seleccionarSucursal($event)">
                                </ng-select-sucursal>
                            </div>
                            <div class="col-sm-6">
                                <label for="usuario" class="col-form-label">Usuario:</label>
                                <ng-select-usuario #searchUsuarios id="ngbdtypeaheadUsuarios"
                                    [cargadorUsuario]="cargadorUsuario" style="width: 100%" name="usuario"
                                    (retornoObjeto)="seleccionarUsuario($event)"></ng-select-usuario>
                            </div>
                        </div>
                        <!-- =========== Para Role cajero ============== -->
                        <div class="form-group row" *ngIf="rol == 'ROLE_CAJERO'">
                            <div class="col-sm-6">
                                <label for="sucursal" class="col-form-label ">Sucursal:</label>
                                <select id="seleccionSucursales" class="form-control form-control-sm custom-select"
                                    [(ngModel)]="codSucursal" style="font-size: 90%;width: 100%; "
                                    name="seleccionSucursales" (ngModelChange)="cambioSucursal($event)">
                                    <option style="font-size: 90%; " *ngFor="let s of sucursales"
                                        [value]="s.codSucursal">
                                        {{ s.nombreSucursal }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="usuario" class="col-form-label">Usuario:</label>
                                <select id="seleccionUsuario" class="form-control form-control-sm custom-select"
                                    [(ngModel)]="seleccionUsuario" style="font-size: 90%;width: 100%; "
                                    name="seleccionUsuario" (ngModelChange)="cambioUsuario($event)">
                                    <option style="font-size: 90%; " *ngFor="let u of usuarios" [value]="u.codUsuario">
                                        {{ u.nombrePersona }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="cliente" class="col-form-label"> Cliente:</label>
                                <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes"
                                    [cargadorCliente]="cargadorCliente" style="width: 100%" name="cliente"
                                    (retornoObjeto)="seleccionarCliente($event)"></ng-select-clientes>
                            </div>
                            <div class="col-sm-3">
                                <label for="nroPedido" class="col-form-label"> Nro pedido:</label>
                                <input type="number" class="form-control form-control" name="nroPedido" id="nroPedido"
                                    [(ngModel)]="nroPedido" min="0">

                            </div>
                            <div class="col-sm-3">
                                <label for="tipoPedido" class="col-form-label">
                                  Tipo:</label>
                                <ng-select [(ngModel)]="objeto.tipoPedido" (ngModelChange)="tipoPedido = $event" name="tipoPedido"
                                  id="tipoPedido" [clearable]="false">
                                  <ng-option [value]="''">TODOS</ng-option>
                                  <ng-option [value]="'POS'">POS</ng-option>
                                  <ng-option [value]="'ECOMMERCE'">ECOMMERCE</ng-option>
                                </ng-select>
                              </div>
                        </div>

                        <div class="form-group row button-group ">
                            <div class="col-sm-6  " style=" text-align: center;">
                                <button class="btn btn-themecolor   waves-effect waves-light " (click)='buscar()'
                                    type="button ">
                                    <i class="fa fa-search "></i>
                                    Buscar
                                </button>
                                <button type="button" (click)="ngOnInit()" class="btn  btn-secondary">
                                    <i class="fa  fa-close"></i> Cancelar
                                </button>
                            </div>
                            <div class="col-sm-6 " style=" text-align: center;">
                            </div>

                        </div>
                    </form>

                </div>

            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex no-block">
                            <div class="m-r-20 align-self-center"><span class="lstick m-r-20"> </span>
                                <img src="../../../assets/images/icon/cash.png" class="w50" alt="Income" />
                            </div>
                            <div class="align-self-center">
                                <h6 class="text-muted m-t-10 m-b-0">Total Gs.</h6>
                                <h2 class="m-t-0">{{totalGs|number}} Gs.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex no-block">
                            <div class="m-r-20 align-self-center"><span class="lstick m-r-20"> </span>
                                <img src="../../../assets/images/icon/balanza.png" class="w50" alt="Income" />
                            </div>
                            <div class="align-self-center">
                                <h6 class="text-muted m-t-10 m-b-0">Total Kg.</h6>
                                <h2 class="m-t-0">{{totalkg|number}} Kg.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex no-block">
                            <div class="m-r-20 align-self-center"><span class="lstick m-r-20"> </span>
                                <img src="../../../assets/images/icon/932602.png" class="w50" alt="Income" />
                            </div>
                            <div class="align-self-center">
                                <h6 class="text-muted m-t-10 m-b-0">Cantidad</h6>
                                <h2 class="m-t-0">{{ totalElementos |number}} </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--     <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="m-r-20 align-self-center">
                <div class="form-group row ">
                   
  
                </div>
              </div>
            </div>
          </div>
        </div> -->
        </div>
    </div>
</div>



<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <div>
                <span>
                    <h3 class="card-title m-b-5 center"><span class="lstick"></span>Detalles</h3>
                </span>
            </div>
        </div>
        <!--   <div class="row">
            <div class="col-lg-5">
                <div class="form-group row">
                    <label for="fechaInicio" class="col-form-label col-sm-4"> F.Desde:</label>
                    <div class="col-sm-8">
                        <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechaInicio"
                            name="fechaInicio">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="fechafin" class="col-form-label col-sm-4"> F.Hasta:</label>
                    <div class="col-sm-8">
                        <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechafin"
                            name="fechafin">
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="form-group row">
                    <label for="cliente" class="col-form-label col-sm-4"> Cliente:</label>
                    <div class="col-sm-8">
                        <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes"
                            [cargadorCliente]="cargadorCliente" style="width: 100%" name="cliente"
                            (retornoObjeto)="seleccionarCliente($event)"></ng-select-clientes>
                    </div>
                </div>
                <div class="form-group row" *ngIf="rol =='ROLE_ADMIN'">
                    <label for="sucursal" class="col-form-label col-sm-4">Sucursal:</label>
                    <div class="col-sm-8">
                        <ng-select-sucursal #searchSucursales id="ngbdtypeaheadSucursales"
                            [cargadorSucursal]="cargadorSucursal" style="width: 100%" name="sucursal"
                            (retornoObjeto)="seleccionarSucursal($event)"></ng-select-sucursal>
                    </div>
                </div>
                <div class="form-group row" *ngIf="rol =='ROLE_ADMIN'">
                    <label for="usuario" class="col-form-label col-sm-4">Usuario:</label>
                    <div class="col-sm-8">
                        <ng-select-usuario #searchUsuarios id="ngbdtypeaheadUsuarios"
                            [cargadorUsuario]="cargadorUsuario" style="width: 100%" name="usuario"
                            (retornoObjeto)="seleccionarUsuario($event)"></ng-select-usuario>
                    </div>
                </div>
                <div class="form-group row" *ngIf="rol =='ROLE_CAJERO'">
                    <label for="sucursal" class="col-form-label col-sm-4">Sucursal:</label>
                    <div class="col-sm-8">
                        <select id="seleccionSucursales" class="form-control form-control-sm custom-select"
                            [(ngModel)]="seleccionSucursal" style="font-size: 90%;width: 100%; "
                            name="seleccionSucursales" (ngModelChange)="cambioSucursal($event)">
                            <option style="font-size: 90%; " *ngFor="let s of sucursales" [value]="s.codSucursal">
                                {{s.nombreSucursal}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="rol =='ROLE_CAJERO'">
                    <label for="usuario" class="col-form-label col-sm-4">Usuario:</label>
                    <div class="col-sm-8">
                        <select id="seleccionUsuario" class="form-control form-control-sm custom-select"
                            [(ngModel)]="seleccionUsuario" style="font-size: 90%;width: 100%; " name="seleccionUsuario"
                            (ngModelChange)="cambioUsuario($event)">
                            <option style="font-size: 90%; " *ngFor="let u of usuarios" [value]="u.codUsuario">
                                {{u.nombrePersona}}
                            </option>
                        </select>
                    </div>
                </div>



            </div>

        </div>
        <div class="form-group row button-group">
            <div class="col-sm-6">
                <button class="btn btn-themecolor   waves-effect waves-light " (click)='buscar()' type="button ">
                    <i class="fa fa-search "></i>
                    Buscar
                </button>
                <button type="button" (click)='ngOnInit()' class="btn  btn-secondary   waves-effect waves-light">
                    <i class="fa  fa-close"></i> Cancelar
                </button>
            </div>
        </div>
 -->
        <div class="form-group row">
            <label for="filas" class="col-form-label col-sm-2 col-lg-1.5 col-xl-1">Filas:</label>
            <div class="col-sm-2 col-lg-1.5 col-xl-1">
                <select class=" form-control form-control-sm custom-select" name="filas" [(ngModel)]="size"
                    (ngModelChange)="cambioNumero($event)">
                    <option *ngFor="let t of numeros" [value]="t.cod">
                        {{t.descripcion}}
                    </option>
                </select>
            </div>
        </div>

        <!--   <div class="form-group row  ">
            <div class="col-sm-2">
                <select class=" form-control form-control-sm custom-select" [(ngModel)]="size" (ngModelChange)="cambioNumero($event)">
                                <option     *ngFor="let t of numeros" [value]="t.cod" >
                                {{t.descripcion}}
                                </option>
                            </select>
            </div>
        </div>
 -->
        <div *ngIf="pedidos?.length == 0" class="alert alert-info">
            No hay registros en la base de datos!
        </div>

        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-6 col-md-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor</span>
                </div>
            </div>
        </div>


        <div class="table-responsive" [hidden]="cargando">
            <div *ngIf="pedidos?.length>0">
                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Codigo</th>
                            <th>Nro pedido</th>
                            <th>Estado</th>
                            <th>Entrega</th>
                            <th>Tipo</th>
                            <th>Sucursal</th>
                            <th>Anulado</th>
                            <th>Cliente</th>
                            <th>Sub Total</th>
                            <th>I.Total</th>
                            <th>Fecha</th>
                            <th>Desc %</th>
                            <th>F.Anulación</th>
                            <th>Detalles</th>
                            <th>Anular</th>
                            <th>Enviar</th>
                            <th>Qr</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of pedidos;let i =index" [ngClass]="{
                              'alert-success':p.estado == 'CONCRETADO',
                              'alert-danger':p.estado == 'ANULADO',
                              'alert-warning':p.estado == 'PENDIENTE'
                              }">
                            <td> {{i+1}}</td>
                            <td>{{ p.codPedido }}</td>
                            <td class="align-middle"> {{p.nroPedido.toString().padStart(8,"00000000") }}</td>
                            <td>{{ p.estado }}</td>
                            <td>{{ p.modoEntrega }}</td>
                            <td>{{ p.tipoPedido }}</td>
                            <td>{{ p.codSucursalErp }}</td>
                            <td *ngIf="p.anulado == true">SI</td>
                            <td *ngIf="p.anulado == false">NO</td>
                            <td>{{ p.cliente.concatDocNombre }}</td>
                            <td align="center" *ngIf="!p.subTotal">0</td>
                            <td align="center" *ngIf="p.subTotal">{{p.subTotal | number }}</td>
                            <td align="center" *ngIf="!p.importeTotal">0</td>
                            <td align="center" *ngIf="p.importeTotal">{{ p.importeTotal | number }}</td>
                            <td align="center" *ngIf="!p.fechaPedido">xx/xx/xx</td>
                            <td align="center" *ngIf="p.fechaPedido">{{ p.fechaPedido | date:'dd/MM/yyyy'}}</td>
                            <td align="center" *ngIf="!p.porcDescuento">0</td>
                            <td align="center" *ngIf="p.porcDescuento">{{ p.porcDescuento | number }}</td>
                            <td align="center" *ngIf="!p.fechaAnulacion">xx/xx/xx</td>
                            <td align="center" *ngIf="p.fechaAnulacion">{{ p.fechaAnulacion }}</td>
                            <td align="center">
                                <button class="btn btn-success btn-circle " (click)="editar(p.codPedido)" type="button">
                                    <i class="fa fa-list"></i>
                                </button>
                            </td>
                            <td align="center">
                                <button class="btn btn-danger btn-circle " (click)="anular(p)" type="button">
                                    <i class="fa  fa-times"></i>
                                </button>

                            </td>
                            <td align="center">
                                <button *ngIf="p.tipoPedido == 'ABI' && p.fechaRetiro == null"
                                    class="btn btn-success btn-circle " (click)="mostrarModal(p)" type="button">
                                    <i class="fa fa-paper-plane"></i>
                                </button>

                            </td>
                            <td align="center">
                                <button *ngIf="p.contrasena != null" class="btn btn-success btn-circle "
                                    (click)="mostrarModalQr(p)" type="button">
                                    <i class="fa fa-qrcode"></i>
                                </button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ngb-pagination #pagination class="d-flex justify-content-center" [size]="tamanhoPag" *ngIf="pedidos?.length>0"
            [collectionSize]="totalElementos" [ellipses]="ellipses" [pageSize]="cantidadElementos" [(page)]="pagina"
            [maxSize]="7" [boundaryLinks]="true" (pageChange)="loadPage($event)">
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationFirst>Primera</ng-template>
            <ng-template ngbPaginationLast>Última</ng-template>
        </ngb-pagination>
    </div>
</div>
<!--  ==================================================Aceptar pedido=========================================================== -->
<div class="fondo-negro animated fadeIn" [ngClass]="modal">
    <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header label-themecolor">
                    <h4 class="text-white ">Confirmar Retiro</h4>
                    <button (click)="cerrarModal()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-sm-6">
                        <label for="fechaRetiro" class="col-form-label">Fecha retiro:</label>
                        <input type="datetime-local" class="form-control form-control-sm" [(ngModel)]="fechaRetiro"
                            id="fechaRetiro" name="fechaRetiro" />
                    </div>
                    <div class="modal-footer">
                        <button (click)="enviar()" type="button" class="btn btn-themecolor  text-white">
                            Enviar
                        </button>
                        <button (click)="cerrarModal()" type="button" class="btn btn-secondary " data-dismiss="modal">
                            cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  ==================================================QR=========================================================== -->
<div class="fondo-negro animated fadeIn" [ngClass]="modalQR">
    <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header label-themecolor">
                    <h4 class="text-white ">Qr</h4>
                    <button (click)="cerrarModalQr()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div style="text-align:center">
                    <h1>
                      Contraseña:  {{value}}
                    </h1>
                    <ngx-qrcode [value]='value'
                    scale = '10'
                    ></ngx-qrcode>
                  </div>
                <div class="modal-body">
                     
                    <div class="modal-footer">
                       
                        <button (click)="cerrarModalQr()" type="button" class="btn btn-secondary " data-dismiss="modal">
                            cerrar
                        </button>
                    </div>
                </div>
            </div>
    </div>
</div>
</div>
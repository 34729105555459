<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.concatDocNombre" [term]="t"></ngb-highlight>
</ng-template>
<div class="form-group">
     



    <div class="input-group" style="width: 100%;"  >
        <input #inputCliente (keyup)="quitarCliente(inputCliente.value)"
         id="typeahead-clientes" type="text"  style="width: 100%;" 
          [class.is-invalid]="searchFailed" class="form-control"
          (selectItem)="selectedItem($event.item)" 
          [(ngModel)]="cargadorClientes" [resultTemplate]="rt"        
          [inputFormatter]="formatter" [ngbTypeahead]="search" 
          (focus)="onFocus($event)"
          placeholder="INGRESE RAZON SOCIAL O RUC" />
        <!-- <div class="input-group-addon" ><i class="fa fa-search"></i></div> -->
   </div>

    <span *ngIf="searching">Buscando...</span>
    <div class="invalid-feedback" *ngIf="searchFailed">
      Lo sentimos, no se pudieron cargar las sugerencias.</div>
  </div>
  
 <!--   <hr>
  <pre>Model: {{ model | json }}</pre> -->
<!-- <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.concatDocNombre" [term]="t"></ngb-highlight>
</ng-template>
<div class="form-group">

    <div class="form-group input-group">

        <input id="typeahead-clientes" [class.is-invalid]="searchFailed" (selectItem)="selectedItem($event.item)" [inputFormatter]="formatter" (ngModel)="model" [resultTemplate]="rt" (focus)="onFocus($event)" [resultTemplate]="rt" type="text" class="form-control form-control-sm"
            [class.is-invalid]="searchFailed" [(ngModel)]="cargadorCliente" [ngbTypeahead]="search" placeholder="Ingrese ruc o razon social ..." />
        <span class="input-group-btn">
    <button class="btn btn-info  "   type="button" style="height: 31px" (click)="limpiar()" ><i class="fa fa-trash fa-xs"></i>
    </button>
  </span>
    </div>
    <span *ngIf="searching">Buscando...</span>
    <div class="invalid-feedback" *ngIf="searchFailed">No se ha encontrado cliente</div>


 -->
<ng-select [items]="clientes" (search)="onSearch($event)" placeholder="Seleccione cliente" (change)="selectedItem($event)" notFoundText="No se ha encontrado cliente" bindLabel="concatDocNombre" [(ngModel)]="cargadorCliente">
    <!--  <ng-template ng-option-tmp let-item="item">
        {{item.concatDocNombre}} <br/>
         <small>{{item.gender}}</small> 
    </ng-template> -->
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <b [ngOptionHighlight]="search">{{item.concatDocNombre}}</b>
    </ng-template>
</ng-select>
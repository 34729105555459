<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!precio.codPrecio" class="m-b-0 text-white">Crear precio</h4>
        <h4 *ngIf="precio.codPrecio" class="m-b-0 text-white">Actualizar precio</h4>
    </div>
    <div class="card-body">

        <form #precioForm="ngForm">

            <div class="form-group row">
                <label for="activo" class="col-form-label col-sm-2">Activo  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="precio.activo" name="activo" id="activo" #activo="ngModel"
                    [clearable]="false">
                    <ng-option [value]="true">SI</ng-option>
                    <ng-option [value]="false">NO</ng-option>
                </ng-select>
                </div>
            </div>

            <div class="form-group row">
                <label for="cantDesde" class="col-form-label col-sm-2">Cantidad Desde <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="precio.cantDesde" name="cantDesde" required #cantDesde="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="cantDesde.invalid && (cantDesde.dirty || cantDesde.touched)">
                    <div *ngIf="!!cantDesde?.errors.required">
                        cantDesde es requerido
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="cantHasta" class="col-form-label col-sm-2">Cantidad Hasta <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="precio.cantHasta" name="cantHasta" required #cantHasta="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="cantHasta.invalid && (cantHasta.dirty || cantHasta.touched)">
                    <div *ngIf="!!cantHasta?.errors.required">
                        cantHasta es requerido
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="precio.cantDesde > precio.cantHasta">
                    <div>
                        cantHasta no puede ser menor a cantidadDesde
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="codPrecioErp" class="col-form-label col-sm-2">Codigo Erp <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="precio.codPrecioErp" (ngModelChange)="precio.codPrecioErp = $event" name="codPrecioErp" #codPrecioErp="ngModel" required minlength="1">
                    <div class="alert alert-danger" *ngIf="codPrecioErp.invalid && (codPrecioErp.dirty || codPrecioErp.touched)">
                        <div *ngIf="!!codPrecioErp?.errors.required">
                            codPrecioErp es requerido
                        </div>
                        <div *ngIf="!!codPrecioErp?.errors.minlength">
                            codPrecioErp debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label for="fechaDesde" class="col-form-label col-sm-2">Fecha Desde <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="precio.fechaDesde" name="fechaDesde" required #fechaDesde="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="fechaDesde.invalid && (fechaDesde.dirty || fechaDesde.touched)">
                    <div *ngIf="!!fechaDesde?.errors.required">
                        fechaDesde es requerido
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaHasta" class="col-form-label col-sm-2">Fecha Hasta <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="precio.fechaHasta" name="fechaHasta" required #fechaHasta="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="fechaHasta.invalid && (fechaHasta.dirty || fechaHasta.touched)">
                    <div *ngIf="!!fechaHasta?.errors.required">
                        fechaHasta es requerido
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="precio.fechaDesde > precio.fechaHasta">
                    <div>
                        fechaHasta no puede ser menor a cantidadDesde
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="precios" class="col-form-label col-sm-2">Precio <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="precio.precio" name="precio" required #precios="ngModel">
                </div>
                <div class="alert alert-danger" *ngIf="precios.invalid && (precios.dirty || precios.touched)">
                    <div *ngIf="!!precios?.errors.required">
                        precio es requerido
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="clientes" class="col-form-label col-sm-2">Clientes</label>
                <div class="col-sm-6">
                    <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes" [cargadorCliente]="cargadorCliente" style="width: 100%" name="cliente" (retornoObjeto)="seleccionarCliente($event)"></ng-select-clientes>
                </div>
            </div>
            <div class="form-group row">
                <label for="productos" class="col-form-label col-sm-2">Producto:</label>
                <div class="col-sm-6">
                    <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto" style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)"></ng-select-producto>
                </div>
            </div>


            <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="listaPrecio" notFoundText="No se ha encontrado lista"
                        placeholder="Seleccione lista" [(ngModel)]="precio.listaPrecio" name="listaPrecio"
                        bindLabel="descripcion" (clear)="cleanLista()" (change)="cambioListaPrecio($event)">
                    </ng-select>

                </div>
            </div>

            <!-- <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="selectListaPrecio" class="custom-select " style="width: 100%; height:36px;" tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionListaPrecio" #selectorListaPrecio="ngModel" name="selectListaPrecio" (ngModelChange)="cambioListaPrecio($event)">
                                                   <option *ngFor="let m of listaPrecio" [value]="m.codListaPrecio">
                                                      {{m.descripcion}}
                                                    </option>
                               </select>
                </div>
            </div> -->

            <div class="form-group row">
                <label for="tipoPrecio" class="col-form-label col-sm-2">Tipo Precio  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <!-- <select id="selectTipoPrecio" class="custom-select " style="width: 100%; height:36px;" tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionTipoPrecio" #selectorTipoPrecio="ngModel" name="selectTipoPrecio" (ngModelChange)="cambioTipoPrecio($event)">
                                                   <option *ngFor="let m of tipoPrecio" [value]="m.codTipoPrecio">
                                                      {{m.descripcion}}
                                                    </option>
                               </select> -->
                               <ng-select [items]="tipoPrecio" notFoundText="No se ha encontrado tipoPrecio"
                               placeholder="Seleccione lista" [(ngModel)]="precio.tipoPrecio" name="tipoPrecio"
                               bindLabel="descripcion" (clear)="cleanTipoPrecio()" (change)="cambioTipoPrecio($event)">
                           </ng-select>
                </div>
            </div>

            <div class="form-group row">
                <label for="unidadMedida" class="col-form-label col-sm-2">Unidad Medida  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="unidadMedida" notFoundText="No se ha encontrado unidadMedida"
                    placeholder="Seleccione lista" [(ngModel)]="precio.unidadMedida" name="unidadMedida"
                    bindLabel="nombreUnidad" (clear)="cleanUnidad()" (change)="cambioUnidad($event)">
                </ng-select>
                    <!-- <select id="selectUnidadMed" class="custom-select " style="width: 100%; height:36px;" tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionUnidad" #selectorUnidad="ngModel" name="selectUnidadMed" (ngModelChange)="cambioUnidad($event)">
                                                   <option *ngFor="let m of unidadMedida" [value]="m.codUnidad">
                                                      {{m.nombreUnidad}}
                                                    </option>
                               </select> -->
                </div>
            </div>


            <div class="form-group row">
                <div class="col-sm-6">
                    <div>
                        <div class="alert alert-danger" *ngIf="!precio.producto && precio.cantDesde">
                            Producto es requerido
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/precios']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!precio.codPrecio else elseBlock" [disabled]="!precioForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!precioForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>

        </form>

    </div>
</div>
<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!medioPago.codMedioPago" class="m-b-0 text-white">Crear Medio Pago</h4>
        <h4 *ngIf="medioPago.codMedioPago" class="m-b-0 text-white">Actualizar Medio Pago</h4>
    </div>
    <div class="card-body">

        <form #medioPagoForm="ngForm">

            <div class="form-group row">
                <label for="codMedioPagoErp" class="col-form-label col-sm-2">Codigo Erp <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="medioPago.codMedioPagoErp"
                        (ngModelChange)="medioPago.codMedioPagoErp = toUpeCaseEvent($event )" name="codMedioPagoErp"
                        #codMedioPagoErp="ngModel" required minlength="1">
                    <div class="alert alert-danger"
                        *ngIf="codMedioPagoErp.invalid && (codMedioPagoErp.dirty || codMedioPagoErp.touched)">
                        <div *ngIf="!!codMedioPagoErp.errors.required">
                            codMedioPagoErp es requerido
                        </div>
                        <div *ngIf="!!codMedioPagoErp.errors.minlength">
                            codMedioPagoErp debe tener al menos 1 caracteres
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Medio de pago <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="medioPago.descripcion"
                        (ngModelChange)="medioPago.descripcion = toUpeCaseEvent($event )" name="descripcion"
                        #descripcion="ngModel" required minlength="5">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 5 caracteres
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group row">
                <label for="esCheque" class="col-form-label col-sm-2">Es Cheque <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="esCheque" id="selectEsCheque"
                        [(ngModel)]="medioPago.esCheque" #esCheque="ngModel">
                        <option value="true">SI</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>


            <div class="form-group row">
                <label for="tieneBanco" class="col-form-label col-sm-2">Tiene Banco <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="tieneBanco" id="selectTieneBanco"
                        [(ngModel)]="medioPago.tieneBanco" #tieneBanco="ngModel">
                        <option value="true">SI</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>




            <div class="form-group row">
                <label for="tieneRef" class="col-form-label col-sm-2">Tiene Ref <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="tieneRef" id="selectTieneRef"
                        [(ngModel)]="medioPago.tieneRef" #tieneRef="ngModel">
                        <option value="true">SI</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>


            <div class="form-group row">
                <label for="tieneTipo" class="col-form-label col-sm-2">Tiene Tipo <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="tieneTipo" id="selectTieneTipo"
                        [(ngModel)]="medioPago.tieneTipo" #tieneTipo="ngModel">
                        <option value="true">SI</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label for="esObsequio" class="col-form-label col-sm-2">Obsequio <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="esObsequio" id="selectesObsequio"
                        [(ngModel)]="medioPago.esObsequio" #esObsequio="ngModel">
                        <option value="true">SI</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>



            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/medioPago']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                        *ngIf="!medioPago.codMedioPago else elseBlock" [disabled]="!medioPagoForm.form.valid"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!medioPagoForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
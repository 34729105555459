<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!bonificacion.codBonificacion" class="m-b-0 text-white">Crear bonificacion</h4>
        <h4 *ngIf="bonificacion.codBonificacion" class="m-b-0 text-white">Actualizar bonificacion</h4>
    </div>
    <div class="card-body">

        <form #bonificacionForm="ngForm">



            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Descripcion <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="bonificacion.descripcion"
                        (ngModelChange)="bonificacion.descripcion =  toUpeCaseEvent($event)" name="descripcion"
                        #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion?.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion?.errors.minlength">
                            descripcion debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label for="fechaDesde" class="col-form-label col-sm-2">Fecha Desde <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="bonificacion.fechaDesde" name="fechaDesde"
                        #fechaDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaHasta" class="col-form-label col-sm-2">Fecha Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="bonificacion.fechaHasta" name="fechaHasta"
                        #fechaHasta="ngModel">

                </div>
            </div>

            <div class="form-group row">
                <label for="cantDesde" class="col-form-label col-sm-2">Cantidad Desde <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="bonificacion.cantDesde" name="cantDesde"
                        #cantDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="cantHasta" class="col-form-label col-sm-2">Cantidad Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="bonificacion.cantHasta" name="cantHasta"
                        #cantHasta="ngModel">

                </div>
            </div>


            <div class="form-group row">
                <label for="activo" class="col-form-label col-sm-2">Activo <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
               
                    <ng-select [(ngModel)]="bonificacion.activo" name="activo" id="activo" #activo="ngModel"
                        [clearable]="false">
                        <ng-option [value]="true">SI</ng-option>
                        <ng-option [value]="false">NO</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="listaPrecio" notFoundText="No se ha encontrado lista"
                        placeholder="Seleccione lista" [(ngModel)]="bonificacion.listaPrecio" name="listaPrecio"
                        bindLabel="descripcion" (clear)="cleanLista()" (change)="cambioListaPrecio($event)">
                    </ng-select>

                </div>
            </div>
            <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="sucursales" notFoundText="No se ha encontrado sucursal"
                        placeholder="Seleccione sucursal" [(ngModel)]="seleccionSucursal" name="sucursal"
                        bindLabel="nombreSucursal" (clear)="cleanSucursal()" (change)="cambioSucursal($event)">
                    </ng-select>
                </div>
            </div>

        
            <div class="form-group row">
                <label for="cantBonif" class="col-form-label col-sm-2">Cantidad Bonif <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="bonificacion.cantBonif" name="cantBonif"
                        #cantBonif="ngModel">
                </div>
            </div>

            <div class="form-group row"     >
            <label for="productos" class="col-form-label col-sm-2">Material Bonif.</label>
            <div class="col-sm-6">
                <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorMaterial"
                    style="width: 100%" name="producto" (retornoObjeto)="seleccionarMaterial($event)">
                </ng-select-producto>
            </div>

        </div>

            <div class="form-group row">
                <label for="tipoBonificacion" class="col-form-label col-sm-2">Tipo Bonificacion <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="bonificacion.tipoBonificacion" name="tipoBonificacion" id="tipoBonificacion"
                        #tipoBonificacion="ngModel" [clearable]="false">
                        <ng-option [value]="'CLIENTE_PRODUCTO'">CLIENTE-PRODUCTO</ng-option>
                        <ng-option [value]="'CLIENTE_KIT'">CLIENTE-KIT</ng-option>
                        <ng-option [value]="'KIT'">KIT</ng-option>
                        <ng-option [value]="'PRODUCTO'">PRODUCTO</ng-option>
                    </ng-select>
                </div>
            </div>


            <div class="form-group row" *ngIf="bonificacion.tipoBonificacion === 'KIT' || bonificacion.tipoBonificacion === 'CLIENTE_KIT' ">
                <label for="grpMaterial" class="col-form-label col-sm-2">Grupo material</label>
                    <div class="col-sm-6">
                        <ng-select [items]="grupoMaterial" notFoundText="No se ha encontrado grupo"
                        placeholder="Seleccione grupo" [(ngModel)]="seleccionGrupoMaterial" name="grupo"
                        bindLabel="descripcion" (clear)="cleanGrupo()" (change)="cambioGrupo($event)">
                    </ng-select>
    
                    </div>
               <!--  <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="bonificacion.grpMaterial"
                    (ngModelChange)="bonificacion.grpMaterial =  toUpeCaseEvent($event)" name="grpMaterial"
                    #grpMaterial="ngModel" required minlength="4">
                </div> -->
            </div>

    
            <div class="form-group row" *ngIf="bonificacion.tipoBonificacion === 'CLIENTE_PRODUCTO' || bonificacion.tipoBonificacion === 'CLIENTE_KIT' ">
                <label for="clientes" class="col-form-label col-sm-2">Clientes</label>
                <div class="col-sm-6">
                    <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes" [cargadorCliente]="cargadorCliente"
                        style="width: 100%" name="cliente" (retornoObjeto)="seleccionarCliente($event)">
                    </ng-select-clientes>
                </div>
            </div>

            <div class="form-group row"
                *ngIf="bonificacion.tipoBonificacion === 'CLIENTE_PRODUCTO' || bonificacion.tipoBonificacion === 'PRODUCTO'">
                <label for="productos" class="col-form-label col-sm-2">Productos</label>
                <div class="col-sm-6">
                    <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto"
                        style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)">
                    </ng-select-producto>
                </div>

            </div>

          

            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/bonificaciones']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                        *ngIf="!bonificacion.codBonificacion else elseBlock" [disabled]="!bonificacionForm.form.valid"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!bonificacionForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
<div class="row">
    <div class="col-lg-8">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <span>
                            <h3 class="card-title m-b-5 center">
                                <span class="lstick"></span>Listado de canjes
                            </h3>
                        </span>
                    </div>
                </div>
                <div class="container py-5">

                    <form #listaCanjesForm="ngForm">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="fechaInicio" class="col-form-label"> F.Desde:</label>
                                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechaInicio"
                                    name="fechaInicio">
                            </div>
                            <div class="col-sm-6">
                                <label for="fechafin" class="col-form-label"> Fecha Hasta:</label>
                                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechafin"
                                    name="fechafin" />
                            </div>
                        </div>

                        <!-- =========== Para Role Adm ============== -->
                        <div class="form-group row" *ngIf="rol == 'ROLE_ADMIN'">
                            <div class="col-sm-6">
                                <label for="sucursal" class="col-form-label">Sucursal:</label>
                                <ng-select-sucursal #searchSucursales id="ngbdtypeaheadSucursales"
                                    [cargadorSucursal]="cargadorSucursal" style="width: 100%" name="sucursal"
                                    (retornoObjeto)="seleccionarSucursal($event)">
                                </ng-select-sucursal>
                            </div>
                            <div class="col-sm-6">
                                <label for="usuario" class="col-form-label">Usuario:</label>
                                <ng-select-usuario #searchUsuarios id="ngbdtypeaheadUsuarios"
                                    [cargadorUsuario]="cargadorUsuario" style="width: 100%" name="usuario"
                                    (retornoObjeto)="seleccionarUsuario($event)"></ng-select-usuario>
                            </div>
                        </div>
                        <!-- =========== Para Role cajero ============== -->
                        <div class="form-group row" *ngIf="rol == 'ROLE_CAJERO'">
                            <div class="col-sm-6">
                                <label for="sucursal" class="col-form-label ">Sucursal:</label>
                                <select id="seleccionSucursales" class="form-control form-control-sm custom-select"
                                    [(ngModel)]="codSucursal" style="font-size: 90%;width: 100%; "
                                    name="seleccionSucursales" (ngModelChange)="cambioSucursal($event)">
                                    <option style="font-size: 90%; " *ngFor="let s of sucursales"
                                        [value]="s.codSucursal">
                                        {{ s.nombreSucursal }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="usuario" class="col-form-label">Usuario:</label>
                                <select id="seleccionUsuario" class="form-control form-control-sm custom-select"
                                    [(ngModel)]="seleccionUsuario" style="font-size: 90%;width: 100%; "
                                    name="seleccionUsuario" (ngModelChange)="cambioUsuario($event)">
                                    <option style="font-size: 90%; " *ngFor="let u of usuarios" [value]="u.codUsuario">
                                        {{ u.nombrePersona }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="cliente" class="col-form-label"> Cliente:</label>
                                <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes"
                                    [cargadorCliente]="cargadorCliente" style="width: 100%" name="cliente"
                                    (retornoObjeto)="seleccionarCliente($event)"></ng-select-clientes>
                            </div>
                            <div class="col-sm-6">
                                <label for="nroCanje" class="col-form-label"> Nro canje:</label>
                                <input type="number" class="form-control form-control" name="nroCanje" id="nroCanje"
                                    [(ngModel)]="nroCanje" min="0">

                            </div>
                        </div>

                        <div class="form-group row button-group ">
                            <div class="col-sm-6  " style=" text-align: center;">
                                <button class="btn btn-themecolor   waves-effect waves-light " (click)='buscar()'
                                    type="button ">
                                    <i class="fa fa-search "></i>
                                    Buscar
                                </button>
                                <button type="button" (click)="ngOnInit()" class="btn  btn-secondary">
                                    <i class="fa  fa-close"></i> Cancelar
                                </button>
                            </div>
                            <div class="col-sm-6 " style=" text-align: center;">
                            </div>

                        </div>
                    </form>

                </div>

            </div>
        </div>
    </div>
    <div class="col-lg-4">

    </div>
</div>



<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <div>
                <span>
                    <h3 class="card-title m-b-5 center"><span class="lstick"></span>Detalles</h3>
                </span>
            </div>
        </div>

        <div class="form-group row">
            <label for="filas" class="col-form-label col-sm-2 col-lg-1.5 col-xl-1">Filas:</label>
            <div class="col-sm-2 col-lg-1.5 col-xl-1">
                <select class=" form-control form-control-sm custom-select" name="filas" [(ngModel)]="size"
                    (ngModelChange)="cambioNumero($event)">
                    <option *ngFor="let t of numeros" [value]="t.cod">
                        {{t.descripcion}}
                    </option>
                </select>
            </div>
        </div>

        <div *ngIf="canjes?.length == 0" class="alert alert-info">
            No hay registros en la base de datos!
        </div>

        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-6 col-md-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor</span>
                </div>
            </div>
        </div>


        <div class="table-responsive" [hidden]="cargando">
            <div *ngIf="canjes?.length>0">
                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Codigo</th>
                            <th>Nro canje</th>
                            <th>Estado</th>
                            <th>Sucursal</th>
                            <th>Anulado</th>
                            <th>Cliente</th>
                            <th>Puntos</th>
                            <th>Fecha</th>
                            <th>F.Anulación</th>
                            <th>Detalles</th>
                            <th>Anular</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of canjes;let i =index" [ngClass]="{
                              'alert-success':p.estado == 'CONCRETADO',
                              'alert-danger':p.estado == 'ANULADO',
                              'alert-warning':p.estado == 'PENDIENTE'
                              }">
                            <td> {{i+1}}</td>
                            <td>{{ p.codCanje }}</td>
                            <td class="align-middle"> {{p.nroCanje.toString().padStart(8,"00000000") }}</td>
                            <td>{{ p.estado }}</td>


                            <td>{{ p.codSucursalErp }}</td>
                            <td *ngIf="p.anulado == true">SI</td>
                            <td *ngIf="p.anulado == false">NO</td>
                            <td>{{ p.cliente.concatDocNombre }}</td>
                            <td align="center">{{p.puntos | number }}</td>

                            <td align="center" *ngIf="!p.fechaCanje">xx/xx/xx</td>
                            <td align="center" *ngIf="p.fechaCanje">{{ p.fechaCanje | date:'dd/MM/yyyy'}}</td>

                            <td align="center" *ngIf="!p.fechaAnulacion">xx/xx/xx</td>
                            <td align="center" *ngIf="p.fechaAnulacion">{{ p.fechaAnulacion| date:'dd/MM/yyyy' }}</td>
                            <td align="center">
                                <button class="btn btn-success btn-circle " (click)="editar(p.codCanje)" type="button">
                                    <i class="fa fa-list"></i>
                                </button>
                            </td>
                            <td align="center">
                                <button class="btn btn-danger btn-circle " (click)="anular(p)" type="button">
                                    <i class="fa  fa-times"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ngb-pagination #pagination class="d-flex justify-content-center" [size]="tamanhoPag" *ngIf="canjes?.length>0"
            [collectionSize]="totalElementos" [ellipses]="ellipses" [pageSize]="cantidadElementos" [(page)]="pagina"
            [maxSize]="7" [boundaryLinks]="true" (pageChange)="loadPage($event)">
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationFirst>Primera</ng-template>
            <ng-template ngbPaginationLast>Última</ng-template>
        </ngb-pagination>
    </div>
</div>
 
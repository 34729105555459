<div class="row">
  <div class="col-lg-8">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div>
            <span>
              <h3 class="card-title m-b-5 center">
                <span class="lstick"></span>Listado de transferencia
              </h3>
            </span>
          </div>
        </div>
        <div class="container py-5">

          <form #repartoForm="ngForm">
            <div class="form-group row">
              <div class="col-sm-6">
                <label for="fechaInicio" class="col-form-label"> Fecha Desde:</label>
                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechaInicio"
                  name="fechaInicio" />
              </div>
              <div class="col-sm-6">
                <label for="fechafin" class="col-form-label"> Fecha Hasta:</label>
                <input type="date" class="form-control form-control-sm  " [(ngModel)]="fechafin" name="fechafin" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6">
                <label for="nroComprobante" class="col-form-label">
                  N° factura:</label>
                <input type="text " placeholder="Ej: 008-008-0000072" [textMask]="{ mask: mask, guide: true }"
                  guide="false " class="form-control form-control-sm " [(ngModel)]="nroComprobante"
                  name="nroComprobante " />
              </div>
              <div class="col-sm-3">
               
              </div>
              <div class="col-sm-3">

              </div>
            </div>


            <div class="form-group row button-group ">
              <div class="col-sm-6  " style=" text-align: center;">
                <button class="btn btn-themecolor   " (click)="buscar()" type="button ">
                  <i class="fa fa-search "></i> Buscar
                </button>
                <button type="button" (click)="ngOnInit()" class="btn  btn-secondary">
                  <i class="fa  fa-close"></i> Cancelar
                </button>
              </div>
              <div class="col-sm-6 " style=" text-align: center;">
              </div>

            </div>
          </form>

        </div>

      </div>
    </div>
  </div>
  <div class="col-lg-4">

  </div>
</div>


<div class="card">
  <div class="card-body">
    <div class="d-flex">
      <div>
        <span>
          <h3 class="card-title m-b-5 center">
            <span class="lstick"></span>Detalles
          </h3>
        </span>
      </div>
    </div>

    <div class="form-group row">
      <label for="filas" class="col-form-label col-sm-2 col-lg-1.5 col-xl-1">Filas:</label>
      <div class="col-sm-2 col-lg-1.5 col-xl-1">
        <select class="selectpicker form-control-sm custom-select" name="filas" [(ngModel)]="size"
          (ngModelChange)="cambioNumero($event)">
          <option *ngFor="let t of numeros" [value]="t.cod">
            {{ t.descripcion }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="transferencias?.length == 0" class="alert alert-info">
      No hay registros en la base de datos!
    </div>

    <div class="row" animated fadeIn *ngIf="cargando">
      <div class="col-sm-6 col-md-12">
        <div class="alert alert-info text-center">
          <strong>Cargando ...</strong>
          <br />
          <i class="fa fa-refresh fa-spin fa-2x"></i>
          <br />
          <span>Espere por favor</span>
        </div>
      </div>
    </div>

    <div class="table-responsive" [hidden]="cargando">
      <div *ngIf="transferencias?.length > 0">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Codigo</th>
              <th>Usuario</th>
              <th>N.Comprobante</th>
              <th>Fecha</th>
              <th>Emisor</th>
              <th>Receptor</th>
              <th>Motivo</th>
              <th>T.Productos</th>
              <th>T.Transferencia</th>
              <th>Detalles</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transferencia of transferencias;let i =index">
              <td> {{i+1}}</td>
              <td>{{ transferencia.codTransferencia }}</td>
              <td>{{ transferencia.usuario }}</td>
              <td align="center" *ngIf="!transferencia.nroComprobante">xxx-xxx-xx</td>
              <td align="center" *ngIf="transferencia.nroComprobante">
                {{ transferencia.nroComprobante }}
              </td>
              <td align="center" *ngIf="!transferencia.fecha">xx/xx/xx</td>
              <td align="center" *ngIf="transferencia.fecha">
                {{ transferencia.fecha | date:'dd/MM/yyyy' }}
              </td>

              <td align="center">
                {{ transferencia.depositoEmisor.nombreDeposito }}
              </td>
              <td align="center">
                {{ transferencia.depositoReceptor.nombreDeposito }}
              </td>
              <td align="center">
                {{ transferencia.motivoTransferencia.descripcion }}
              </td>
              <td align="center">
                {{ transferencia.totalProducto }}
              </td>
              <td align="center">
                {{ transferencia.totalTransferencia }}
              </td>
              <td align="center">
                <button class="btn btn-success btn-circle " (click)="editar(transferencia.codTransferencia)"
                  type="button">
                  <i class="fa fa-list"></i>
                </button>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ngb-pagination #pagination class="d-flex justify-content-center" *ngIf="transferencias?.length > 0"
      [size]="tamanhoPag" [collectionSize]="totalElementos" [ellipses]="ellipses" [pageSize]="cantidadElementos"
      [(page)]="pagina" [maxSize]="5" [boundaryLinks]="true" (pageChange)="loadPage($event)">
      <ng-template ngbPaginationPrevious>&laquo;</ng-template>
      <ng-template ngbPaginationNext>&raquo;</ng-template>
      <ng-template ngbPaginationFirst>Primera</ng-template>
      <ng-template ngbPaginationLast>Última</ng-template>
    </ngb-pagination>
  </div>
</div>
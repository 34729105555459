<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <div>
                <h3 class="card-title m-b-5 center"><span class="lstick"></span>Top Productos </h3>
            </div>
        </div>
        <div class="col-lg-12 card">
            <div class="row form-inline card-body">

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xs-12 input-group" style="margin-bottom: 0.5rem" data-toggle="tooltip" title="Seleccione tipo de medio por el cual desea filtrar">
                    <select id="seleccionTipo" class=" form-control form-control-sm custom-select" style="font-size: 90%;width: 100%" [(ngModel)]="seleccionTipo" name="seleccionTipo" (ngModelChange)="cambio($event)">
                        <option  style="font-size: 90%;width: 100%"   *ngFor="let t of tipos" [value]="t.cod">
                          {{t.descripcion}}
                        </option>
                    </select>
                </div>&nbsp;
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xs-12 input-group" style="margin-bottom: 0.5rem" data-toggle="tooltip" title="Seleccione el numero de items que desea filtrar">
                    <select style="font-size: 90%;width: 100%" class=" form-control form-control-sm custom-select" [(ngModel)]="seleccionNumero" (ngModelChange)="cambioNumero($event)" (ngModelChange)="cambioNumero($event)">
                        <option  style="font-size: 90%;width: 100%"   *ngFor="let t of numeros" [value]="t.cod" >
                          {{t.descripcion}}
                        </option>
                       </select>
                </div>&nbsp;

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xs-12">
                    <button class="btn btn-themecolor btn-rounded  waves-effect waves-light" type="button" (click)="traerDatos(fechaDesde,fechaHasta,codUsuario,codSucursal)">
                                        <i class="fa fa-search "></i>
                            </button>
                </div>

            </div>

            <div *ngIf="cargado">
                <ngx-charts-barra-vertical [dashTema]="dashTema" legendTitle="Productos" [results]="topResult"></ngx-charts-barra-vertical>

            </div>
            <div *ngIf="!cargado" class="row " style="display: block; width: 100%; height: 330px; padding: 0.5rem">
                <div class="col-lg-12">
                    <div class="card   ">
                        <div class="card-body  ">
                            <!-- <div class="d-flex">
                                       <div class="stats">
                                           <h1 style="font:bold 1.5 em verdana,arial;" class="text-white  m-t-30 m-b-30">NO SE HAN ENCONTRADO RESULTADOS </h1>
                                        </div>
                                     
                                    
                                        <div class="stats-icon text-right ml-auto">
                                               <img src="assets/images/users/alert.png"  >
                                       </div>
                                   </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="stats-bar">
            <div class="row text-center">
                <div class="col-lg-3 col-md-3">
                    <div class="p-20">
                        <h6 class="m-b-0">T. Importe GS.</h6>
                        <h3 class="m-b-0">{{totalImporte | number }}</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="p-20">
                        <h6 class="m-b-0">T. Cantidad prods</h6>
                        <h3 class="m-b-0">{{totalcantidad | number }} </h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="p-20">
                        <h6 class="m-b-0">T. Peso Kg.</h6>
                        <h3 class="m-b-0">{{totalpeso | number }} </h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="p-20">
                        <h6 class="m-b-0">T. Ventas</h6>
                        <h3 class="m-b-0">{{totalcantidadVenta | number }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- <ul class="alert alert-danger" *ngIf="errores?.length > 0">
  <li *ngFor="let err of errores">
    {{ err.field + " " + err.defaultMessage }}
  </li>
</ul> -->

<div class="card ">
  <div class="card-header label-themecolor">
    <h4 *ngIf="!chofer.codChofer" class="m-b-0 text-white">Crear chofer</h4>
    <h4 *ngIf="chofer.codChofer" class="m-b-0 text-white"> Actualizar chofer </h4>
  </div>
  <div class="card-body">
    <form #choferForm="ngForm">


      <div class="form-group row">
        <label for="activo" class="col-form-label col-sm-2">Activo <span style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <select class=" custom-select  form-control" name="activo" id="selectActivo" [(ngModel)]="chofer.activo">
            <option value="true">SI</option>
            <option value="false">NO</option>
          </select>
        </div>
      </div>


      <div class="form-group row">
        <label for="docNro" class="col-form-label col-sm-2">CI <span style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="chofer.docNro"
            (ngModelChange)="chofer.docNro =  toUpeCaseEvent($event)" name="docNro" required minlength="4" />
        </div>
      </div>

      <div class="form-group row">
        <label for="choferes" class="col-form-label col-sm-2">Chofer <span style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="chofer.chofer"
            (ngModelChange)="chofer.chofer = toUpeCaseEvent($event)" name="choferes" required minlength="4" />
        </div>
      </div>


      <div class="form-group row">
        <label for="tipoLicencia" class="col-form-label col-sm-2">Tipo licencia <span
            style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <select class=" custom-select  form-control" name="tipoLicencia" id="tipoLicencia"
            [(ngModel)]="chofer.tipoLicencia">
            <option value="PARTICULAR" selected>PARTICULAR</option>
            <option value="PROFESIONAL A">PROFESIONAL A</option>
            <option value="PROFESIONAL B">PROFESIONAL B</option>
            <option value="PROFESIONAL C">PROFESIONAL C</option>
            <option value="EXTRANJERO">EXTRANJERO</option>
          </select>
        </div>
      </div>


      <div class="form-group row">
        <label for="licencia" class="col-form-label col-sm-2">Licencia <span style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="chofer.licencia"
            (ngModelChange)="chofer.licencia =  toUpeCaseEvent($event)" name="licencia" required minlength="4" />

        </div>
      </div>


      <div class="form-group row button-group">
        <div class="col-sm-6">
          <button type="button" [routerLink]="['/choferes']" class="btn btn-danger waves-effect waves-light">
            <i class="fa  fa-arrow-circle-left"></i> Atrás
          </button>
          <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)="create()"
            *ngIf="!chofer.codChofer; else elseBlock" [disabled]="!choferForm.form.valid">
            <i class="fa   fa-floppy-o"></i>Crear
          </button>
          <ng-template #elseBlock>
            <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)="update()"
              [disabled]="!choferForm.form.valid">
              <i class="fa  fa-floppy-o"></i>Actualizar
            </button>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</div>
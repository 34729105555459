<!-- ============================================================== -->
<!--                          PEDIDO                                 -->
<!-- ============================================================== -->
<div class="row" *ngIf="pedido">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                 <!--Estado de pago-->   
                <!--<div class="alert alert-warning d-flex justify-content-center"> -->
                    <div class="alert alert-success d-flex justify-content-center" *ngIf="pagado">
                        <i class="fa fa-check-circle fa-2x"></i>
                        <div class="ml-2 mt-2 text-center">
                            <b> PAGADO ONLINE </b>
                       </div>
                    </div>
                    
                    <div class="alert alert-warning d-flex justify-content-center" *ngIf="!pagado">
                        <i class="fa fa-exclamation-circle fa-2x"></i>
                        <div class="ml-2 mt-2 text-center">
                            <b> PENDIENTE DE COBRO </b>
                       </div>
                    </div>

                <!--Observacion del pedido-->
                <div class="alert alert-info d-flex justify-content-center" *ngIf="pedido?.observacion?.length > 0">
                    <i class="fa fa-exclamation-circle fa-2x"></i>
                    <div class="ml-2 mt-2 text-center">
                        <b> Observación: {{ pedido?.observacion }} </b>
                    </div>
                 </div>

                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Pedido</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>

                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="pedido">
                            <thead>
                                <tr>
                                    <th>Codigo</th>
                                    <th>Nro pedido</th>
                                    <th>Cliente</th>
                                    <th>Lista Precio</th>
                                    <th>Vendedor</th>
                                    <th>Cod vendedor</th>
                                    <th>F. Venta</th>
                                    <th>F. Modificacion</th>
                                    <th>F. Anulacion</th>
                                    <th>Imp.Iva5</th>
                                    <th>Imp.Iva10</th>
                                    <th>Exenta</th>
                                    <th>Descuento Imp</th>
                                    <th>Descuento Prd</th>
                                    <th>Imp.Neto</th>
                                    <th>Sub Total</th>
                                    <th>Imp.Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ pedido.codPedido }}
                                    </td>
                                    <td>
                                        <h6> {{pedido.nroPedido.toString().padStart(8,"00000000") }}</h6>
                                    </td>

                                    <td>
                                        <h6>{{pedido.cliente.docNro}}</h6>
                                        <small class="text-muted">{{ pedido.cliente.razonSocial }}</small>
                                    </td>
                                    <td>
                                        <h6>{{pedido.listaPrecio.descripcion}}</h6> 
                                    </td>
                                    <td>
                                        <h6>{{pedido.vendedor.docNro}}</h6><small class="text-muted">{{
                                            pedido.vendedor.vendedor }}</small>
                                    </td>
                                    <td>
                                        <h6>{{pedido.codVendedorErp}}</h6> 
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.fechaPedido | date:'dd/MM/yyyy' }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.fechaModificacion | date:'dd/MM/yyyy, h:mm a' }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6 *ngIf="!pedido.fechaAnulacion">
                                            xx/xx/xxxx
                                        </h6>
                                        <h6 *ngIf="pedido.fechaAnulacion">
                                            {{ pedido.fechaAnulacion | date:'dd/MM/yyyy, h:mm a' }}
                                        </h6>
                                    </td>

                                    <td>
                                        <h6>
                                            {{ pedido.importeIva5 | number }} Gs.
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.importeIva10 | number }} Gs.
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.importeIvaExenta | number }} Gs.
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>{{ pedido.porcDescuento | number }} %</h6>
                                        <small class="text-muted">{{ pedido.importeDescuento | number }}
                                            Gs.</small>
                                    </td>
                                    <td>
                                        <h6>{{ pedido.descuentoProducto | number }} Gs. </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.importeNeto | number }} Gs.
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.subTotal | number }} Gs.
                                        </h6>
                                    </td>
                                    <td>
                                        <h6>
                                            {{ pedido.importeTotal | number }} Gs.
                                        </h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- ============================================================== -->
<!--                    Detalles de Pedido                           -->
<!-- ============================================================== -->
<div class="row" *ngIf="detalles?.length>0">
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Detalles</h4>
                    <!--   <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i> Atrás
                        </button>
                    </div> -->
                </div>
                <perfect-scrollbar class="scroll-container"
                    style="position: relative; max-width: 100%; max-height: 600px;" [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <div class="table-responsive m-t-20">
                            <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="detalles">
                                <thead>
                                    <tr>
                                        <th colspan="3">Producto</th>
                                        <th>Unidad</th>
                                        <th>Cantidad</th>
                                        <th>Precio</th>
                                        <th>%IVA</th>
                                        <th>%IVA5</th>
                                        <th>%IVA 10</th>
                                        <th>%IVA ex</th>
                                        <th>% Des</th>
                                        <th>Descuento</th>
                                        <th>Tipo Desc.</th>
                                        <th>Neto</th>
                                        <th>Sub total</th>
                                        <th>Total</th>
                                        <th colspan="2">Vendedor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalle of detalles">
                                        <td>
                                            {{ detalle.nroItem }}
                                        </td>
                                        <td align="center" class="w70" align="center" style="background: white">
                                            <img *ngIf="detalle.producto.img"
                                                [attr.src]="detalle.producto.img | imagen:'productos' | async"
                                                class="img-producto60" />
                                            <img *ngIf="!detalle.producto.img" [src]="sinImagen" class="img-producto60">
                                        </td>
                                        <!--  <td style="width:40px;">
                                            <img *ngIf="detalle.producto.img" class="img-lista"
                                                [attr.src]="detalle.producto.img | imagen:'productos' | async">
                                            <img *ngIf="!detalle.producto.img" class="img-lista" [src]="sinImagen">
                                        </td> -->
                                        <td>
                                            <h6>{{ detalle.producto.codProductoErp }}</h6><small class="text-muted">{{
                                                detalle.producto.concatCodNombre }}</small>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.unidadMedida.nombreUnidad }}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.cantidad | number }}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importePrecio | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.porcIva | number }}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeIva5 | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeIva10 | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeIvaExenta | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.porcDescuento | number }} %
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeDescuento | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.tipoDescuento }}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeNeto | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.subTotal | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>
                                                {{ detalle.importeTotal | number }} Gs.
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>{{detalle.vendedor.docNro}}</h6>
                                            <small class="text-muted">{{ detalle.vendedor.vendedor }}</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf="cliente && cliente.latitud != 0 && cliente.longitud != 0" >
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Ubicación</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <br>
                <perfect-scrollbar class="scroll-container"
                    style="position: relative; max-width: 100%; max-height: 600px;" [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <agm-map [latitude]="cliente.latitud" [longitude]="cliente.longitud" [zoom]="zoomMap"  >
                            <agm-marker #marker   [latitude]="cliente.latitud"
                                [longitude]="cliente.longitud"  (markerClick)="clickedMarker(infowindow)">
                                <agm-info-window #infowindow   >
                                    <span class="form-inline">
                                        <strong>Razon social:</strong>
                                        <strong> {{ cliente.razonSocial }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <strong>Nro doc:</strong>
                                        <strong> {{ cliente.docNro }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <strong>Tel/cel:</strong>
                                        <strong> {{ cliente.telefono }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <strong>Dirección:</strong>
                                        <strong> {{ cliente.direccion }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <strong>Longitud:</strong>
                                        <strong> {{ cliente.longitud }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <strong>Latitud:</strong>
                                        <strong> {{ cliente.latitud }} </strong>
                                    </span>
                                    <span class="form-inline">
                                        <label class="small text-uppercase my-1" for="categorias">Link:</label>
                                        <a class="text-primary pointer" (click)='openLink()'>
                                            <label>{{linkBase+cliente.latitud+","+cliente.longitud}}</label>
                                        </a>
                                    </span>
                                </agm-info-window>
                        
                            </agm-marker>
                        
                        </agm-map>
                    </div>
                </perfect-scrollbar>
              
            </div>
        </div>
    </div>
</div>


<!-- ============================================================== -->
<!--                          Orden                                 -->
<!-- ============================================================== -->
<div class="row" *ngIf="pedido">
    <div class="col-lg-12" *ngIf="pedido.orden">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Orden</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="pedido">
                            <thead>
                                <tr>
                                    <th>Cod. orden</th>
                                    <th>Cod. Ecommerce</th>
                                    <th>Fecha</th>
                                    <th>Sucursal</th>
                                    <th>Total</th>
                                    <th>Delivery</th>
                                    <th>Estado</th>
                                    <!-- <th>Metodo</th> -->

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ pedido.orden.codOrden }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.codOrdenEcommerce }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.fechaOrden | date:'dd/MM/yyyy' }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.branchName }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.ordenTotal | number}} Gs.
                                    </td>
                                    <td>
                                        {{ pedido.orden.deliveryMethod }}
                                    </td>
                                    <td *ngIf="pedido.orden.paymentStatus =='PAGADO'" [ngClass]=" 'alert-success'">
                                        PAGADO ONLINE
                                    </td>
                                    <td *ngIf="pedido.orden.paymentStatus !='PAGADO'" [ngClass]=" 'alert-warning'">
                                        PENDIENTE DE COBRO
                                    </td>

                                    <!-- <td *ngIf="pedido.orden.paymentMethod =='contra_entrega'"  >
                                  -
                                </td>
                                <td *ngIf="pedido.orden.paymentMethod !='contra_entrega'"  >
                                    {{ pedido.orden.paymentMethod }}
                                </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>



<!-- ============================================================== -->
<!--                          Envio                                 -->
<!-- ============================================================== -->
<!-- <div class="row" *ngIf="pedido && _loginServices.user.authorities[0] == 'ROLE_ADMIN'">
    <div class="col-lg-12" *ngIf="pedido.orden">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Envio</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="pedido">
                            <thead>
                                <tr>
                                    <th>Departamento</th>
                                    <th>Ciudad</th>
                                    <th>Barrio</th>
                                    <th>telefono</th>
                                    <th>Direccion</th>
                                    <th>Obs</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ pedido.orden.shippingDepartment }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.shippingCity }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.shippingNeighborhood   }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.shippingPhone }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.shippingAddress  }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.shippingObservation }}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>   -->

<!-- ============================================================== -->
<!--                          Facturacion                           -->
<!-- ============================================================== -->
<!-- <div class="row" *ngIf="pedido">
    <div class="col-lg-12" *ngIf="pedido.orden">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Facturación</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="pedido">
                            <thead>
                                <tr>
                                    <th>Departamento</th>
                                    <th>Ciudad</th>
                                    <th>Barrio</th>
                                    <th>telefono</th>
                                    <th>Direccion</th>
                                    <th>Obs</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ pedido.orden.billingDepartment }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.billingCity }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.billingNeighborhood   }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.billingPhone }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.billingAddress  }}
                                    </td>
                                    <td>
                                        {{ pedido.orden.billingObservation }}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
 -->







<!-- ============================================================== -->
<!--                          Cobranza                              -->
<!-- ============================================================== -->
<div class="row" *ngIf="cobranza">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Cobranza</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button *ngIf="pedido.estado == 'PENDIENTE'" class="btn btn-info btn-rounded"
                            (click)="mostrarModal()" type="button">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>

                <div class="align-self-center">
                    <div class="table-responsive m-t-20">
                        <table class="table vm no-th-brd no-wrap pro-of-month" *ngIf="cobranza">
                            <thead>
                                <tr>
                                    <th>Codigo Cobranza</th>
                                    <th>Imp.Cobrado</th>
                                    <th>Imp.Abonado</th>
                                    <th>Vuelto</th>
                                    <th>F. Cobranza</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ cobranza.codCobranza }}</td>
                                    <td>{{ cobranza.importeCobrado | number }} Gs.</td>
                                    <td>{{ cobranza.importeAbonado | number }} Gs.</td>
                                    <td>{{ cobranza.saldo | number }} Gs.</td>
                                    <td>{{ cobranza.fechaCobranza | date:'dd/MM/yy' }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ============================================================== -->
<!--                    Detalles de cobranza                        -->
<!-- ============================================================== -->
<div class="row" *ngIf="cobranzaDetalles?.length>0">
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                    <div class="d-flex">
                        <h4 class="card-title"><span class="lstick"></span>Detalles</h4>
                        <!--   <div class="btn-group ml-auto m-t-10">
                            <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                                <i class="fa  fa-arrow-circle-left"></i> Atrás
                            </button>
                        </div> -->
                    </div>
                   
                <perfect-scrollbar class="scroll-container"
                    style="position: relative; max-width: 100%; max-height: 600px;" [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <div class="table-responsive m-t-20">

                            <table class="table vm no-th-brd no-wrap pro-of-month">
                                <thead>
                                    <tr>
                                        <th>Codigo</th>
                                        <th>ImporteAbonado</th>
                                        <th>ImporteCobrado</th>
                                        <th>Saldo</th>
                                        <th>MedioPago</th>
                                        <th>NroRef</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalle of cobranzaDetalles">
                                        <td>{{ detalle.codCobranzaDetalle }}</td>
                                        <td>{{ detalle.importeAbonado | number }} Gs.</td>
                                        <td>{{ detalle.importeCobrado | number }} Gs.</td>
                                        <td>{{ detalle.saldo | number }} Gs.</td>
                                        <td>{{ detalle.medioPago.descripcion }}</td>
                                        <td *ngIf="!detalle.nroRef || detalle.nroRef == '' ">XXX-XXX-XXX-XXX </td>
                                        <td *ngIf="detalle.nroRef && detalle.nroRef != '' ">{{ detalle.nroRef }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>


<!-- ============================================================== -->
<!--                    Detalles de descuentos                      -->
<!-- ============================================================== -->
<div class="row" *ngIf="descuentos?.length>0">
    <div class="col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <h4 class="card-title"><span class="lstick"></span>Descuentos</h4>
                    <div class="btn-group ml-auto m-t-10">
                        <button class="btn btn-success btn-rounded" (click)="atras()" type="button">
                            <i class="fa  fa-arrow-circle-left"></i>
                        </button>
                    </div>
                </div>
                <perfect-scrollbar class="scroll-container"
                    style="position: relative; max-width: 100%; max-height: 600px;" [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <div class="table-responsive m-t-20">
                            <table class="table vm no-th-brd no-wrap pro-of-month">
                                <thead>
                                    <tr>
                                        <!--  <th>Codigo</th> -->
                                        <th>Descripcion</th>
                                        <th>Tipo</th>
                                        <th>Descuento</th>
                                        <th>F. Desde</th>
                                        <th>F. Hasta</th>
                                        <th>C. Desde</th>
                                        <th>C. Hasta</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let descuento of descuentos">
                                        <!--    <td>{{ descuento.codDescuento }}</td> -->
                                        <td>{{ descuento.descripcion }}</td>
                                        <td>{{ descuento.tipoDescuento }}</td>
                                        <td>{{ descuento.descuento }}%</td>
                                        <td>{{ descuento.fechaDesde | date:'dd/MM/yy'}}</td>
                                        <td>{{ descuento.fechaHasta | date:'dd/MM/yy' }}</td>
                                        <td>{{ descuento.cantDesde | number }}</td>
                                        <td>{{ descuento.cantHasta | number }}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>


<!--  ===========================================================Modal Cobranzas====================================== -->
<div class="fondo-negro-t animated fadeIn" [ngClass]="oculto">
    <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4>Cobranza</h4>
                    <button (click)="cerrarModal()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <perfect-scrollbar class="scroll-container" style="position: relative; max-height: 600px;"
                    [scrollIndicators]="true">
                    <div class="scrollable-content">
                        <div class="modal-body" *ngIf="oculto == '' ">
                            <div class="card-body">
                                <div>
                                    <div class="widget-body">
                                        <form #CobranzaForm="ngForm" role="form">
                                            <fieldset>
                                                <div class="row show-grid">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Medio Pago Pref.</label>
                                                            <select id="selectMedioPago" class="custom-select"
                                                                style="width: 100%; " tabindex="-1" aria-hidden="true"
                                                                [(ngModel)]="seleccionMedioPago" #selectMedio="ngModel"
                                                                name="selectMedio"
                                                                (ngModelChange)="cambioMedioPago($event)">
                                                                <option *ngFor="let m of medioPago"
                                                                    [value]="m.codMedioPago">
                                                                    {{m.descripcion}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" *ngIf="selectModelMedio.tieneTipo">
                                                        <div class="form-group" *ngIf="selectModelMedio.tieneTipo">
                                                            <label>Tipo</label>
                                                            <select id="selectTipoMedioPago"
                                                                class="form-control custom-select form-group"
                                                                style="width: 100%; " tabindex="-1" aria-hidden="true"
                                                                [(ngModel)]="selectModelTipoMedioPago"
                                                                (ngModelChange)="cambioTipo($event)"
                                                                name="selectModelTipoMedio">
                                                                <option [ngValue]="t" *ngFor="let t of tipoMedioPago;">
                                                                    {{t.descripcion}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" *ngIf="selectModelMedio.tieneBanco">
                                                        <div class="form-group" *ngIf="selectModelMedio.tieneBanco">
                                                            <label>Banco </label>
                                                            <select id="selectBanco"
                                                                class="form-control custom-select form-group"
                                                                style="width: 100%; " tabindex="-1" aria-hidden="true"
                                                                [(ngModel)]="selectModelBanco"
                                                                (ngModelChange)="cambioBanco($event)"
                                                                name="selectBanco">
                                                                <option [ngValue]="b" *ngFor="let b of bancos;">
                                                                    {{b.descripcion}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="selectModelMedio.esCheque">
                                                        <div class="form-group" *ngIf="selectModelMedio.esCheque">
                                                            <label>Fecha Emision </label>
                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="fechaEmision" name="fechaEmision" required>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="selectModelMedio.esCheque">
                                                        <div class="form-group" *ngIf="selectModelMedio.esCheque">
                                                            <label>Fecha Vencimiento </label>
                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="fechaVencimiento" name="fechaVencimiento"
                                                                required>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3"
                                                        *ngIf="selectModelMedio.esCheque || selectModelMedio.tieneRef">
                                                        <div class="form-group"
                                                            *ngIf="selectModelMedio.esCheque || selectModelMedio.tieneRef">
                                                            <label>Nro referencia</label>
                                                            <input type="text" class="form-control" [(ngModel)]="nroRef"
                                                                (ngModelChange)="nroRef =  toUpeCaseEvent($event)"
                                                                name="nroRef" required minlength="4">
                                                            <div *ngIf="nroRef">
                                                                <div *ngIf="nroRef.length > 0 || 4 > nroRef.length ">
                                                                    <div *ngIf="4 > nroRef.length"
                                                                        class="alert alert-danger">
                                                                        Nro referencia debe tener al menos 4 caracteres
                                                                        y se han
                                                                        ingresado {{nroRef.length}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="selectModelMedio.esCheque">
                                                        <div class="form-group" *ngIf="selectModelMedio.esCheque">
                                                            <label>Nro cuenta</label>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="nroCuenta"
                                                                (ngModelChange)="nroCuenta =  toUpeCaseEvent($event)"
                                                                name="nroCuenta" required minlength="12">
                                                            <div *ngIf="nroCuenta">
                                                                <div
                                                                    *ngIf="nroCuenta.length > 0 || 12 > nroCuenta.length ">
                                                                    <div *ngIf="12 > nroCuenta.length"
                                                                        class="alert alert-danger">
                                                                        Nro cuenta debe tener al menos 12 caracteres y
                                                                        se han
                                                                        ingresado {{nroCuenta.length}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Monto </label>
                                                            <input type="number" min="100" class="form-control currency"
                                                                name="montoAbonado" id="inputmontoAbonado"
                                                                [(ngModel)]="montoAbonado" required />
                                                        </div>
                                                    </div>
                                                </div>



                                            </fieldset>
                                        </form>
                                    </div>
                                </div>

                                <div class="form-group row button-group">
                                    <div class="col-md-3">

                                        <button type="button" align="right" (click)="agregarCobranza()"
                                            [disabled]="!CobranzaForm.form.valid" class="btn btn-success text-white">
                                            Agregar
                                        </button>
                                    </div>
                                </div>



                                <div class="ventas-contenedor-body-20">
                                    <table id="myTable2"
                                        class="font-weight-bold table table-fixedheader table-bordered table-striped">
                                        <thead style="overflow-y: hidden">
                                            <tr>
                                                <th width="45%">Medio Pago</th>
                                                <th width="45%">Importe</th>
                                                <th height="120%" width="10%"><i class="fa fa-trash-o"></i>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody style="height:120px">
                                            <tr *ngFor="let item of cobranzasDetalle">
                                                <td width="45%"> {{item.medioPago.descripcion}} </td>
                                                <td width="45%"> {{item.importeAbonado | number }}</td>
                                                <td width="10%"> <button (click)="quitarCobranza(item)"
                                                        class="brn btn-danger"><i class="fa fa-trash-o"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 full-color-table full-muted-table hover-table"
                                            style="width:100%;padding:1rem; border: 15% raddius;">
                                            <table class="font-weight-bold">
                                                <thead>
                                                    <tr *ngIf="cobranza">
                                                        <td width="40%" class="bg-success">Total a Pagar</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%" class="bg-success"> : Gs.
                                                            {{ pedido.importeTotal | number}}</td>
                                                        <td width="10%">
                                                            <button (click)="usar(pedido.importeTotal)"
                                                                title="Click para agregar a Monto"
                                                                *ngIf="pedido.importeTotal > totalAbonado && (totalAbonado + pedido.importeTotal) == pedido.importeTotal"
                                                                type="button" class="btn btn-sm btn-success"><i
                                                                    class="fa fa-plus"></i></button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td width="40%" class="bg-info">Total Abonado</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%" class="bg-info"> : Gs.
                                                            {{ totalAbonado | number}}</td>
                                                        <td width="10%"> </td>

                                                    </tr>
                                                    <tr class="alert-warning"
                                                        *ngIf="pedido.importeTotal > totalAbonado && totalAbonado > 0">
                                                        <td class="bold" width="40%"> Diferencia</td>
                                                        <td class="bold" width="10%"> </td>
                                                        <td class="bold" width="40%">: Gs.
                                                            {{ pedido.importeTotal - totalAbonado | number}}
                                                        </td>
                                                        <td width="10%">
                                                            <button (click)="usar(pedido.importeTotal - totalAbonado)"
                                                                *ngIf="(pedido.importeTotal - totalAbonado) > 0 "
                                                                type="button" class="btn btn-sm btn-success"><i
                                                                    class="fa fa-plus"></i></button>
                                                        </td>

                                                    </tr>
                                                    <tr *ngIf="vuelto >= 1">
                                                        <td width="40%" class="bg-warning"> Vuelto</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%" class="bg-warning">: Gs. {{vuelto | number}}
                                                        </td>
                                                        <td width="10%"> </td>

                                                    </tr>
                                                    <tr *ngIf="1 > vuelto">
                                                        <td width="40%"> Vuelto</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%">: Gs. 0,0</td>
                                                        <td width="10%"> </td>

                                                    </tr>

                                                </thead>
                                            </table>
                                        </div>
                                        <div class="col-md-6 full-color-table full-muted-table hover-table"
                                            style="width:100%;padding:1rem; border: 15% raddius;">
                                            <table class="font-weight-bold">
                                                <thead>
                                                    <tr>
                                                        <td width="40%"> Total iva 5%</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%">: Gs. {{ pedido.importeIva5 | number}}</td>
                                                        <td width="10%"> </td>

                                                    </tr>
                                                    <tr>
                                                        <td width="40%"> Total iva 10%</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%">: Gs. {{ pedido.importeIva10 | number}}</td>
                                                        <td width="10%"> </td>

                                                    </tr>
                                                    <tr>
                                                        <td width="40%"> Total exenta</td>
                                                        <td width="10%"> </td>
                                                        <td width="40%">: Gs. {{ pedido.importeIvaExenta | number}}</td>
                                                        <td width="10%"> </td>

                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button (click)="guardarCobranza()" type="button"
                                    class="btn btn-themecolor text-white">Guardar</button>
                                <button (click)="cancelarModal()" type="button" class="btn btn-secondary"
                                    data-dismiss="modal">Volver</button>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>







<!--  ===========================================================Modal Vendedor Det====================================== -->
<div class="fondo-negro-t animated fadeIn" [ngClass]="modalOrden">
    <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header label-themecolor">
                    <h4 class="text-white">Orden</h4>
                    <button (click)="cancelarModalOrden()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="pedido">
                    <div class="row mx-3" *ngIf="pedido.orden">
                        <div class="col-md-2 form-group">
                            <label for="textbox1">codigo </label>
                            <input class="form-control" id="codOrden" [(ngModel)]="pedido.orden.codOrden"
                                disabled="true" type="text" />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="textbox1">Cod.Ecommerce</label>
                            <input class="form-control" id="codOrdenEcommerce"
                                [(ngModel)]="pedido.orden.codOrdenEcommerce" disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="fechaOrden">Fecha</label>
                            <input class="form-control" id="fechaOrden" [(ngModel)]="pedido.orden.fechaOrden"
                                disabled="true" type="text" />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="ordenTotal"> Total</label>
                            <input class="form-control" id="ordenTotal" [(ngModel)]="pedido.orden.ordenTotal"
                                disabled="true" type="text" />
                        </div>



                        <div class="col-md-3 form-group">
                            <label for="shippingDepartment">Depart. envio</label>
                            <input class="form-control" id="shippingDepartment"
                                [(ngModel)]="pedido.orden.shippingDepartment" disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="shippingCity">Ciudad envio</label>
                            <input class="form-control" id="shippingCity" [(ngModel)]="pedido.orden.shippingCity"
                                disabled="true" type="text" />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="shippingNeighborhood">Barrio envio</label>
                            <input class="form-control" id="shippingNeighborhood"
                                [(ngModel)]="pedido.orden.shippingNeighborhood" disabled="true" type="text" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label for="shippingPhone">Tel.envio</label>
                            <input class="form-control" id="shippingPhone" [(ngModel)]="pedido.orden.shippingPhone"
                                disabled="true" type="text" />
                        </div>


                        <div class="col-md-6 form-group">
                            <label for="shippingAddress">Direccion envio</label>
                            <input class="form-control" id="shippingAddress" [(ngModel)]="pedido.orden.shippingAddress"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="shippingObservation">Obs envio</label>
                            <input class="form-control" id="shippingObservation"
                                [(ngModel)]="pedido.orden.shippingObservation" disabled="true" type="text" />
                        </div>




                        <div class="col-md-3 form-group">
                            <label for="billingDepartment">Depart. Fact</label>
                            <input class="form-control" id="billingDepartment"
                                [(ngModel)]="pedido.orden.billingDepartment" disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="billingCity">Ciudad Fact</label>
                            <input class="form-control" id="billingCity" [(ngModel)]="pedido.orden.billingCity"
                                disabled="true" type="text" />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="billingNeighborhood">Barrio Fact</label>
                            <input class="form-control" id="billingNeighborhood"
                                [(ngModel)]="pedido.orden.billingNeighborhood" disabled="true" type="text" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label for="billingPhone">Tel.Fact</label>
                            <input class="form-control" id="billingPhone" [(ngModel)]="pedido.orden.billingPhone"
                                disabled="true" type="text" />
                        </div>


                        <div class="col-md-6 form-group">
                            <label for="billingAddress">Direccion Fact</label>
                            <input class="form-control" id="billingAddress" [(ngModel)]="pedido.orden.billingAddress"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="billingObservation">Obs Fact</label>
                            <input class="form-control" id="billingObservation"
                                [(ngModel)]="pedido.orden.billingObservation" disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="paymentStatus">Status</label>
                            <input class="form-control" id="paymentStatus" [(ngModel)]="pedido.orden.paymentStatus"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="paymentMethod">Metodo</label>
                            <input class="form-control" id="paymentMethod" [(ngModel)]="pedido.orden.paymentMethod"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="paymentDate">Fecha</label>
                            <input class="form-control" id="paymentDate" [(ngModel)]="pedido.orden.paymentDate"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="paymentCode">Nro de pago</label>
                            <input class="form-control" id="paymentCode" [(ngModel)]="pedido.orden.paymentCode"
                                disabled="true" type="text" />
                        </div>


                        <div class="col-md-3 form-group">
                            <label for="deliveryPaymentMethod">Delivery Metodo</label>
                            <input class="form-control" id="deliveryPaymentMethod"
                                [(ngModel)]="pedido.orden.deliveryPaymentMethod" disabled="true" type="text" />
                        </div>


                        <div class="col-md-3 form-group">
                            <label for="deliveryMethod">Delivery </label>
                            <input class="form-control" id="deliveryMethod" [(ngModel)]="pedido.orden.deliveryMethod"
                                disabled="true" type="text" />
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="branchName">Sucursal </label>
                            <input class="form-control" id="branchName" [(ngModel)]="pedido.orden.branchName"
                                disabled="true" type="text" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="form-group row button-group">
                            <div class="col-sm-12 align-self-center" style=" text-align: center;" aling="center">

                                <button class="btn btn-secondary " (click)="cancelarModalOrden()" type="button"
                                    data-dismiss="modal">
                                    <i class="fa  fa-arrow-circle-left"></i>
                                    Atrás
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  ===========================================================Modal Vendedor Cab ====================================== -->
import { Pedido } from './pedido.model';
import { Venta } from './venta.model';
import { Reparto } from './reparto.model';
import { Cliente } from './cliente.model';

export class RepartoDocs {
    //  ? significa opcional
        constructor(
            public codRepartoDocs: number,
            public venta: Venta,
            public pedido: Pedido,
            public cliente: Cliente,
            public reparto: Reparto,
            public totalKg: number,
            public totalGs: number,
            public docNro: string,
            public tipo: string,
            public fechaReparto: string,
            public latitud: number,
            public longitud: number,
            public orden: number,
        ) { }
    }

    // Generated by https://quicktype.io

export interface Marcador {
  codRepartoDocs: number;
  codCliente:     number;
  codVenta:       number;
  codReparto:     number;
  entregado:      boolean;
  importe:        number;
  comprobante:    string;
  razonSocial:    string;
  docNro:         string;
  direccion:      string;
  telefono:       string;
  latitud:        number;
  longitud:       number;
  orden:          number;
  distancia:      number;
}

<ul class="alert alert-danger" *ngIf="errores?.length > 0">
  <li *ngFor="let err of errores">
    {{ err.field + ' ' + err.defaultMessage }}
  </li>
</ul>

<div class="card">
  <div class="card-header label-themecolor">
    <h4 *ngIf="!cliente.codCliente" class="m-b-0 text-white">Crear cliente</h4>
    <h4 *ngIf="cliente.codCliente" class="m-b-0 text-white">
      Actualizar cliente
    </h4>
  </div>
  <div class="card-body">
    <form #clienteForm="ngForm">
      <div class="form-group row">
        <div class="col-md-6">
          <div class="form-group row">
            <label for="razonSocial" class="col-form-label col-sm-4"
              >Razon Social <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.razonSocial"
                (ngModelChange)="cliente.razonSocial = toUpeCaseEvent($event)"
                name="razonSocial"
                #razonSocial="ngModel"
                required
                minlength="4"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  razonSocial.invalid &&
                  (razonSocial.dirty || razonSocial.touched)
                "
              >
                <div *ngIf="!!razonSocial?.errors.required">
                  Razon Social es requerido
                </div>
                <div *ngIf="!!razonSocial?.errors.minlength">
                  Razon Social debe tener al menos 4 caracteres
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="tipoDoc" class="col-form-label col-sm-4"
              >Tipo Documento <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.tipoDoc"
                name="tipoDoc"
                id="selectTipoDoc"
                #tipoDoc="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="'RUC'">RUC</ng-option>
                <ng-option [value]="'CI'">CI</ng-option>
                <ng-option [value]="'CE'">CE</ng-option>
              </ng-select>
            </div>
          </div>
          <app-nro-docu
            *ngIf="mostrarDoc"
            [tipoDoc]="cliente.tipoDoc"
            [docNro]="cliente.docNro"
            (docNroChange)="setNroDoc($event)"
          ></app-nro-docu>

          <div class="form-group row">
            <label for="telefono" class="col-form-label col-sm-4"
              >Telefono <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.telefono"
                (ngModelChange)="cliente.telefono = toUpeCaseEvent($event)"
                name="telefono"
                #telefono="ngModel"
                required
                minlength="6"
              />
              <div
                class="alert alert-danger"
                *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)"
              >
                <div *ngIf="!!telefono?.errors.required">
                  telefono es requerido
                </div>
                <div *ngIf="!!telefono?.errors.minlength">
                  telefono debe tener al menos 6 caracteres
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="direccion" class="col-form-label col-sm-4"
              >Direccion <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.direccion"
                (ngModelChange)="cliente.direccion = toUpeCaseEvent($event)"
                name="direccion"
                #direccion="ngModel"
                required
                minlength="3"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  direccion.invalid && (direccion.dirty || direccion.touched)
                "
              >
                <div *ngIf="!!direccion?.errors.required">
                  direccion es requerido
                </div>
                <div *ngIf="!!direccion?.errors.minlength">
                  direccion debe tener al menos 3 caracteres
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="listaPrecio" class="col-form-label col-sm-4"
              >Lista Precio <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [items]="listaPrecio"
                notFoundText="No se ha encontrado lista"
                placeholder="Seleccione lista"
                [(ngModel)]="cliente.listaPrecio"
                name="listaPrecio"
                bindLabel="descripcion"
                (clear)="cleanLista()"
                (change)="cambioListaPrecio($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="sucursales" class="col-form-label col-sm-4"
              >Sucursal <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [items]="sucursales"
                notFoundText="No se ha encontrado sucursal"
                placeholder="Seleccione sucursal"
                [(ngModel)]="cliente.sucursalPref"
                name="sucursal"
                bindLabel="nombreSucursal"
                (clear)="cleanSucursal()"
                (change)="cambioSucursal($event)"
              >
              </ng-select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="user.authorities[0] == 'ROLE_ADMIN'"
          >
            <label for="activo" class="col-form-label col-sm-4"
              >Activo <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.activo"
                name="activo"
                id="activo"
                #activo="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="user.authorities[0] == 'ROLE_ADMIN'"
          >
            <label for="predeterminado" class="col-form-label col-sm-4"
              >Predeterminado <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.predeterminado"
                name="predeterminado"
                id="predeterminado"
                #predeterminado="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="user.authorities[0] == 'ROLE_ADMIN'"
          >
            <label for="esPropietario" class="col-form-label col-sm-4"
              >Propietario <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.esPropietario"
                name="esPropietario"
                id="esPropietario"
                #esPropietario="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="user.authorities[0] == 'ROLE_ADMIN'"
          >
            <label for="empleado" class="col-form-label col-sm-4"
              >Empleado <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.empleado"
                name="empleado"
                id="empleado"
                #empleado="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="user.authorities[0] == 'ROLE_ADMIN'"
          >
            <label for="catABC" class="col-form-label col-sm-4"
              >Categoria ABC <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.catABC"
                name="catABC"
                id="catABC"
                #catABC="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="'A'">A</ng-option>
                <ng-option [value]="'B'">B</ng-option>
                <ng-option [value]="'C'">C</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <agm-map
                name="mapas"
                id="mapas"
                (mapClick)="agregarMarcador($event)"
                [latitude]="lat"
                [longitude]="lng"
                [zoom]="10"
              >
                <agm-marker
                  [latitude]="cliente.latitud"
                  [longitude]="cliente.longitud"
                >
                  <agm-info-window>
                    <strong>{{ cliente.razonSocial }}</strong>
                    <strong>{{ cliente.docNro }}</strong>
                    <p>{{ cliente.direccion }}</p>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </div>
          </div>
          <div class="form-group row button-group">
            <div class="col-sm-6">
              <button
                type="button"
                [routerLink]="['/clientes']"
                class="btn btn-danger waves-effect waves-light"
              >
                <i class="fa fa-arrow-circle-left"></i> Atrás
              </button>
              <button
                class="btn btn-info waves-effect waves-light"
                type="button"
                role="button"
                (click)="create()"
                *ngIf="!cliente.codCliente; else elseBlock"
                [disabled]="!clienteForm.form.valid"
              >
                <i class="fa fa-floppy-o"></i>Crear
              </button>
              <ng-template #elseBlock>
                <button
                  class="btn btn-info waves-effect waves-light"
                  type="button"
                  role="button"
                  (click)="update()"
                  [disabled]="!clienteForm.form.valid"
                >
                  <i class="fa fa-floppy-o"></i>Actualizar
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label for="excentoIva" class="col-form-label col-sm-4"
              >Excento Iva <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.excentoIva"
                name="excentoIva"
                id="excentoIva"
                #excentoIva="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="medioPago" class="col-form-label col-sm-4"
              >Medio Pago Pref. <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [items]="medioPago"
                notFoundText="No se ha encontrado medio pago"
                placeholder="Seleccione medio pago"
                [(ngModel)]="cliente.medioPagoPref"
                name="selectMedio"
                bindLabel="descripcion"
                (clear)="cleanMedio()"
                (change)="cambioMedioPago($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="formaVenta" class="col-form-label col-sm-4"
              >Forma venta <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [items]="formaVenta"
                notFoundText="No se ha encontrado forma venta"
                placeholder="Seleccione forma venta"
                [(ngModel)]="cliente.formaVentaPref"
                name="formaVentaPref"
                bindLabel="descripcion"
                (clear)="cleanForma()"
                (change)="cargarForma($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="codClienteErp" class="col-form-label col-sm-4"
              >Codigo Ref.
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.codClienteErp"
                (ngModelChange)="cliente.codClienteErp = toUpeCaseEvent($event)"
                name="codClienteErp"
                #codClienteErp="ngModel"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="codeBarra" class="col-form-label col-sm-4"
              >Tiene Cod barra <span style="color: red">(*)</span></label
            >
            <div class="col-sm-6">
              <ng-select
                [(ngModel)]="cliente.codeBarra"
                name="codeBarra"
                id="codeBarra"
                #codeBarra="ngModel"
                [clearable]="false"
              >
                <ng-option [value]="true">SI</ng-option>
                <ng-option [value]="false">NO</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-form-label col-sm-4">Email </label>
            <div class="col-sm-6">
              <input
                type="email"
                class="form-control"
                [(ngModel)]="cliente.email"
                name="email"
                #email="ngModel"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="categoria" class="col-form-label col-sm-4"
              >Grupo descuento</label
            >
            <div class="col-sm-6">
              <ng-select
                [items]="grupos"
                notFoundText="No se ha encontrado grupo"
                placeholder="Seleccione estado"
                [(ngModel)]="cliente.grupoDescuento"
                name="estado"
                bindLabel="descripcion"
                (clear)="cleanGrupo()"
                (change)="cambioGrupo($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="carnetGrupo" class="col-form-label col-sm-4"
              >Nro carnet</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.carnetGrupo"
                (ngModelChange)="cliente.carnetGrupo = toUpeCaseEvent($event)"
                name="carnetGrupo"
                #carnetGrupo="ngModel"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="carnetVencimiento" class="col-form-label col-sm-4"
              >F. vencimiento carnet</label
            >
            <div class="col-sm-6">
              <input
                type="date"
                class="form-control"
                [(ngModel)]="cliente.carnetVencimiento"
                name="carnetVencimiento"
                #carnetVencimiento="ngModel"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="obs" class="col-form-label col-sm-4">Observación</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cliente.obs"
                (ngModelChange)="cliente.obs = toUpeCaseEvent($event)"
                name="obs"
                #obs="ngModel"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="latitud" class="col-form-label col-sm-4">Latitud</label>
            <div class="col-sm-6">
              <input
                type="number"
                class="form-control"
                id="latitud"
                name="latitud"
                [(ngModel)]="cliente.latitud"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="longitud" class="col-form-label col-sm-4"
              >Longitud</label
            >
            <div class="col-sm-6">
              <input
                type="number"
                class="form-control"
                id="longitud"
                name="longitud"
                [(ngModel)]="cliente.longitud"
                required
              />
            </div>
          </div>
          <!--*ngIf="user.codEmpresa != 1"-->
          <div class="form-group row" *ngIf="user.codEmpresa != 1">
            <button
              class="btn btn-info waves-effect waves-light w-100"
              type="button"
              role="button"
              (click)="abrirModal()"
              [disabled]="!clienteForm.form.valid"
            >
              <i class="fa fa-map-marker"></i> Ubicación
            </button>
          </div>
          <div class="form-group row">
            <button
              class="btn btn-info waves-effect waves-light w-100"
              type="button"
              role="button"
              (click)="abrirModalAnalizarUrl()"
            >
              Analizar URL de Google Maps
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!--Modal clientes-google-->
<div class="fondo-negro animated fadeIn" [ngClass]="modalGoogle">
  <div class="modal" style="display: block" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header label-themecolor">
          <h4 class="text-white">Cliente Google</h4>
        </div>
        <perfect-scrollbar
          class="scroll-container"
          style="position: relative; max-height: 850px"
          [scrollIndicators]="true"
        >
          <div class="scrollable-content">
            <div class="modal-body" *ngIf="modalGoogle == ''">
              <div class="card-body">
                <label for="cliente" class="col-form-label"> Cliente:</label>
                <ng-select-cgoogle
                  #searchClientes
                  id="ngbdtypeaheadClientes"
                  [cargadorClienteGoogle]="cargadorClienteGoogle"
                  style="width: 100%"
                  name="cliente"
                  (retornoObjeto)="seleccionarClienteGoogle($event)"
                ></ng-select-cgoogle>
              </div>

              <!--mapa-->
              <div
                class="row"
                *ngIf="
                  clienteGoogle &&
                  clienteGoogle.latitud != 0 &&
                  clienteGoogle.longitud != 0
                "
              >
                <div class="col-lg-12 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex">
                        <h4 class="card-title">
                          <span class="lstick"></span>Ubicación
                        </h4>
                      </div>
                      <br />
                      <perfect-scrollbar
                        class="scroll-container"
                        style="
                          position: relative;
                          max-width: 100%;
                          max-height: 600px;
                        "
                        [scrollIndicators]="true"
                      >
                        <div class="scrollable-content" *ngIf="clienteGoogle">
                          <agm-map
                            [latitude]="clienteGoogle.latitud"
                            [longitude]="clienteGoogle.longitud"
                            [zoom]="zoomMap"
                          >
                            <agm-marker
                              #marker
                              [latitude]="clienteGoogle.latitud"
                              [longitude]="clienteGoogle.longitud"
                              (markerClick)="clickedMarker(infowindow)"
                            >
                              <agm-info-window #infowindow>
                                <span class="form-inline">
                                  <strong>Cliente:</strong>
                                  <strong> {{ clienteGoogle.name }} </strong>
                                </span>
                                <span class="form-inline">
                                  <strong>Dirección:</strong>
                                  <strong> {{ clienteGoogle.address }} </strong>
                                </span>
                                <span class="form-inline">
                                  <strong>Longitud:</strong>
                                  <strong>
                                    {{ clienteGoogle.longitud }}
                                  </strong>
                                </span>
                                <span class="form-inline">
                                  <strong>Latitud:</strong>
                                  <strong> {{ clienteGoogle.latitud }} </strong>
                                </span>
                                <span class="form-inline">
                                  <label
                                    class="small text-uppercase my-1"
                                    for="categorias"
                                    >Link:</label
                                  >
                                  <a
                                    class="text-primary pointer"
                                    (click)="openLink()"
                                  >
                                    <label>{{
                                      linkBase +
                                        clienteGoogle.latitud +
                                        ',' +
                                        clienteGoogle.longitud
                                    }}</label>
                                  </a>
                                </span>
                              </agm-info-window>
                            </agm-marker>
                          </agm-map>
                        </div>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  (click)="confirmarCliente()"
                  type="button"
                  *ngIf="
                    cargadorClienteGoogle &&
                    cargadorClienteGoogle.latitud &&
                    cargadorClienteGoogle.longitud
                  "
                  class="btn btn-themecolor text-white"
                >
                  Confirmar
                </button>
                <button
                  (click)="cerrarModal()"
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>

<div class="fondo-negro animated fadeIn" [ngClass]="modalUrlGoogle">
  <div class="modal" style="display: block" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header label-themecolor">
          <h4 class="text-white">Ingrese Url de Maps</h4>
        </div>

        <input
          type="text"
          [(ngModel)]="urlGoogle"
          placeholder="Pegar URL aquí"
        />
        <button (click)="analizarUrl()">Analizar URL</button>
      </div>
    </div>
  </div>
</div>

export class FormaVenta {
    //  ? significa opcional
            public codFormaVenta: number;
            public codEmpresa: number;
            public cantDias: number;
            public descripcion: string;
            public codFormaVentaErp: string;
            public esContado: boolean;

            constructor(  ) { }
    }

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 

import {
  StockPremioService,
  InfluencersService,
  AlianzasService,
  HistorialPuntosService,
  CanjesService,
  PremioService,
  PuntoService,
  GrupoMaterialService,
  BonificacionService,
  GrupoDescuentoService,
  MotivoTransferenciaService,
  MotivoAnulacionCompraService,
  CompraService,
  ProveedorService,
  ExcelService,
  VendedorService,
  ChoferService,
  VehiculoService,
  RepartoService,
  PrecioMaterialService,
  TipoDepositoService,
  StockService,
  PedidosService,
  UsuarioService,
  PrecioService,
  CategoriaService,
  UnidadMedidaService,
  DescuentoService,
  EmpresasService,
  SucursalesService,
  MedioPagoService,
  ComprobantesService,
  ListaPrecioService,
  TipoPrecioService,
  BancosService,
  DepositoService,
  MotivoAnulacionService,
  ClienteService,
  TipoMedioPagoService,
  ProductoService,
  VentasService,
  FormaPagoService,
  TerminalService,
  SettingsService,
  SidebarService,
  LayoutService,
  LoginGuardGuard,
  AuthGuard,
  SubirArchivoService,
  FormaVentaService,
  DashboardService,
  RolService,
  LoginService,
  VerificaTokenGuard,
  InterceptorService,
  TransferenciaService,
  AbiProductoService,
  AbiOrdenService,
  CuponService,
  HistorialPremioService,
  BalanzaService
} from './service.index';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    HistorialPremioService,
    StockPremioService,
    InfluencersService,
    AlianzasService,
    HistorialPuntosService,
    CanjesService,
    PremioService,
    PuntoService,
    GrupoMaterialService,
    BonificacionService,
    GrupoDescuentoService,
    MotivoTransferenciaService,
    MotivoAnulacionCompraService,
    CompraService,
    ProveedorService,
    ExcelService,
    VendedorService,
    ChoferService,
    VehiculoService,
    RepartoService,
    PrecioMaterialService,
    TipoDepositoService,
    StockService,
    PedidosService,
    UsuarioService,
    EmpresasService,
    ComprobantesService,
    BancosService,
    DepositoService,
    MotivoAnulacionService,
    UnidadMedidaService,
    SucursalesService,
    LoginService,
    TipoMedioPagoService,
    DashboardService,
    RolService,
    CategoriaService,
    TerminalService,
    TipoPrecioService,
    FormaVentaService,
    MedioPagoService,
    ListaPrecioService,
    PrecioService,
    DescuentoService,
    ClienteService,
    ProductoService,
    VentasService,
    FormaPagoService,
    LayoutService,
    SettingsService,
    SidebarService,
    SubirArchivoService,
    LoginGuardGuard,
    AuthGuard,
    VerificaTokenGuard,
    InterceptorService,
    TransferenciaService,
    AbiProductoService,
    AbiOrdenService,
    CuponService,
    BalanzaService
  ],
  declarations: []
})
export class ServiceModule { }

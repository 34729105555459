<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!descuento.codDescuento" class="m-b-0 text-white">Crear descuento</h4>
        <h4 *ngIf="descuento.codDescuento" class="m-b-0 text-white">Actualizar descuento</h4>
    </div>
    <div class="card-body">

        <form #descuentoForm="ngForm">



            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Descripcion <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="descuento.descripcion"
                        (ngModelChange)="descuento.descripcion =  toUpeCaseEvent($event)" name="descripcion"
                        #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger"
                        *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion?.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion?.errors.minlength">
                            descripcion debe tener al menos 4 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="descuentos" class="col-form-label col-sm-2">Descuento <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="descuento.descuento" name="descuento"
                        #descuentos="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaDesde" class="col-form-label col-sm-2">Fecha Desde <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="descuento.fechaDesde" name="fechaDesde"
                        #fechaDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="fechaHasta" class="col-form-label col-sm-2">Fecha Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="descuento.fechaHasta" name="fechaHasta"
                        #fechaHasta="ngModel">

                </div>
            </div>

            <div class="form-group row">
                <label for="cantDesde" class="col-form-label col-sm-2">Cantidad Desde <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="descuento.cantDesde" name="cantDesde"
                        #cantDesde="ngModel">
                </div>
            </div>

            <div class="form-group row">
                <label for="cantHasta" class="col-form-label col-sm-2">Cantidad Hasta <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="descuento.cantHasta" name="cantHasta"
                        #cantHasta="ngModel">

                </div>
            </div>


            <div class="form-group row">
                <label for="activo" class="col-form-label col-sm-2">Activo <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <!-- <select class=" custom-select  form-control" name="activo" id="selectActivo" [(ngModel)]="descuento.activo" #activo="ngModel">
                    <option value="true">SI</option>
                    <option value="false" >NO</option>
                  </select> -->
                    <ng-select [(ngModel)]="descuento.activo" name="activo" id="activo" #activo="ngModel"
                        [clearable]="false">
                        <ng-option [value]="true">SI</ng-option>
                        <ng-option [value]="false">NO</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="listaPrecio" notFoundText="No se ha encontrado lista"
                        placeholder="Seleccione lista" [(ngModel)]="descuento.listaPrecio" name="listaPrecio"
                        bindLabel="descripcion" (clear)="cleanLista()" (change)="cambioListaPrecio($event)">
                    </ng-select>

                </div>
            </div>
            <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="sucursales" notFoundText="No se ha encontrado sucursal"
                        placeholder="Seleccione sucursal" [(ngModel)]="seleccionSucursal" name="sucursal"
                        bindLabel="nombreSucursal" (clear)="cleanSucursal()" (change)="cambioSucursal($event)">
                    </ng-select>
                </div>
            </div>

            <!-- <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="seleccionSucursales" class="custom-select" style="width: 100%; height:36px;"
                        tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionSucursal" #selectSucursal="ngModel"
                        name="seleccionSucursales" (ngModelChange)="cambioSucursal($event)">
                        <option *ngFor="let s of sucursales" [value]="s.codSucursal">
                            {{s.nombreSucursal}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label for="listaPrecio" class="col-form-label col-sm-2">Lista Precio <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="selectListaPrecio" class="custom-select " style="width: 100%; height:36px;"
                        tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionListaPrecio"
                        #selectorListaPrecio="ngModel" name="selectListaPrecio"
                        (ngModelChange)="cambioListaPrecio($event)">
                        <option *ngFor="let m of listaPrecio" [value]="m.codListaPrecio">
                            {{m.descripcion}}
                        </option>
                    </select>
                </div>
            </div> -->


            <div class="form-group row">
                <label for="unidadDescuento" class="col-form-label col-sm-2">Unidad Descuento <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <!-- <select class=" custom-select  form-control" name="unidadDescuento" id="unidadDescuento" [(ngModel)]="descuento.unidadDescuento" #unidadDescuento="ngModel">
              <option value="PORCENTAJE">PORCENTAJE</option>
              <option value="IMPORTE" >IMPORTE</option>
            </select> -->
                    <ng-select [(ngModel)]="descuento.unidadDescuento" name="unidadDescuento" id="unidadDescuento"
                        #unidadDescuento="ngModel" [clearable]="false">
                        <ng-option [value]="'PORCENTAJE'">PORCENTAJE</ng-option>
                        <ng-option [value]="'IMPORTE'">IMPORTE</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="form-group row">
                <label for="tipoDescuento" class="col-form-label col-sm-2">Tipo Descuento <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <!-- <select class=" custom-select  form-control" (ngModelChange)="cambioTipoDescuento($event)" name="tipoDescuento" id="tipoDescuento" [(ngModel)]="descuento.tipoDescuento" #tipoDescuento="ngModel">
              <option value="CLIENTE">CLIENTE</option>
              <option value="IMPORTE" >IMPORTE</option>
              <option value="PRODUCTO" >PRODUCTO</option>
              <option value="UNO_DE_DOS" >UNO_DE_DOS</option>
              <option value="MEDIO_PAGO">MEDIO PAGO</option>
              <option value="NORMAL">NORMAL</option>
              <option value="SUCURSAL">SUCURSAL</option>
            </select> -->
                    <ng-select [(ngModel)]="descuento.tipoDescuento" name="tipoDescuento" id="tipoDescuento"
                        #tipoDescuento="ngModel" [clearable]="false">
                        <ng-option [value]="'CLIENTE'">CLIENTE</ng-option>
                        <ng-option [value]="'IMPORTE'">IMPORTE</ng-option>
                        <ng-option [value]="'PRODUCTO'">PRODUCTO</ng-option>
                        <ng-option [value]="'UNO_DE_DOS'">UNO_DE_DOS</ng-option>
                        <!-- <ng-option [value]="'MEDIO_PAGO'">MEDIO_PAGO</ng-option> -->
                        <!-- <ng-option [value]="'NORMAL'">NORMAL</ng-option> -->
                        <ng-option [value]="'SUCURSAL'">SUCURSAL</ng-option>
                    </ng-select>
                </div>
            </div>


            <div class="form-group row" *ngIf="descuento.tipoDescuento === 'CLIENTE'">
                <label for="clientes" class="col-form-label col-sm-2">Clientes</label>
                <div class="col-sm-6">
                    <ng-select-clientes #searchClientes id="ngbdtypeaheadClientes" [cargadorCliente]="cargadorCliente"
                        style="width: 100%" name="cliente" (retornoObjeto)="seleccionarCliente($event)">
                    </ng-select-clientes>
                </div>
            </div>

            <div class="form-group row"
                *ngIf="descuento.tipoDescuento === 'PRODUCTO' || descuento.tipoDescuento === 'UNO_DE_DOS'">
                <label for="productos" class="col-form-label col-sm-2">Productos</label>
                <div class="col-sm-6">
                    <ng-select-producto #searchProductos id="selectproducto" [cargadorProducto]="cargadorProducto"
                        style="width: 100%" name="producto" (retornoObjeto)="seleccionarProducto($event)">
                    </ng-select-producto>
                </div>

            </div>

            <div class="form-group row" *ngIf="descuento.tipoDescuento === 'MEDIO_PAGO'">
                <label for="medioPago" class="col-form-label col-sm-2">Medio Pago <span
                        style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="selectMedioPago" class="custom-select f" style="width: 100%; height:36px;" tabindex="-1"
                        aria-hidden="true" [(ngModel)]="seleccionMedioPago" #selectMedio="ngModel" name="selectMedio"
                        (ngModelChange)="seleccionarMedioPago($event)">
                        <option *ngFor="let m of medioPago" [value]="m.codMedioPago">
                            {{m.descripcion}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/descuentos']"
                        class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i>
                        Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()'
                        *ngIf="!descuento.codDescuento else elseBlock" [disabled]="!descuentoForm.form.valid"><i
                            class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button"
                            (click)='update()' [disabled]="!descuentoForm.form.valid"><i
                                class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
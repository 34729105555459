<ul class="alert alert-danger" *ngIf="errores?.length > 0">
    <li *ngFor="let err of errores">
        {{ err.field +" "+ err.defaultMessage}}
    </li>
</ul>

<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!terminal.codTerminalVenta" class="m-b-0 text-white">Crear terminal</h4>
        <h4 *ngIf="terminal.codTerminalVenta" class="m-b-0 text-white">Actualizar terminal</h4>
    </div>
    <div class="card-body">

        <form #terminalForm="ngForm">


            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Terminal <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="terminal.descripcion" (ngModelChange)="terminal.descripcion =  toUpeCaseEvent($event)" name="descripcion" #descripcion="ngModel" required minlength="4">
                    <div class="alert alert-danger" *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 4 caracteres
                        </div>

                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="sucursales" class="col-form-label col-sm-2">Sucursal  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select id="seleccionSucursales" class="custom-select" style="width: 100%; height:36px;" tabindex="-1" aria-hidden="true" [(ngModel)]="seleccionSucursal" #selectSucursal="ngModel" name="seleccionSucursales" (ngModelChange)="cambioSucursal($event)">
                                         <option *ngFor="let s of sucursales" [value]="s.codSucursal">
                                            {{s.nombreSucursal}}
                                          </option>
                     </select>
                </div>
            </div>

            <!-- <div class="form-group row">
        <label for="disponible" class="col-form-label col-sm-2">Disponible  <span style="color: red">(*)</span></label>
        <div class="col-sm-6">
          <select class=" custom-select  form-control" name="tipoDoc" id="selectDisponible" [(ngModel)]="terminal.disponible" #disponible="ngModel"  >
            <option value="true"  >SI</option>
            <option value="false">NO</option>
          </select>
          
        </div>
      </div>
  -->

            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/terminales']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!terminal.codTerminalVenta else elseBlock" [disabled]="!terminalForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!terminalForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
<div class="card border-primary mb-3">
    <div class="card-header label-themecolor">
        <h4 class="m-b-0 text-white">Listado de motivos de transferencia( {{ motivos.length}})</h4>
    </div>
    <div class="card-body text-dark">
        <div class="row form-group">
            <div class="col-3">
                <button class="btn btn-rounded   btn-success" type="button" [routerLink]="['/motivoTransferencia/formulario']">
              <i class="fa fa-plus"></i>
              Nuevo
            </button>
            </div>

            <div class="col-3"></div>
        </div>
        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor</span>
                </div>
            </div>
        </div>

        <div *ngIf="motivos?.length == 0" class="alert alert-info">
            No se han encontrado registros ...
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-striped" *ngIf="motivos?.length>0">
                <thead>
                    <tr>
                        <!--   <th>Codigo</th> -->
                        <th>Codigo Erp</th>
                        <!--    <th>Codigo empresa</th> -->
                        <th>Motivo</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let m of motivos | slice: (currentPage * pageSize) - pageSize :currentPage * pageSize">
                        <!--  <td>{{ m.codmotivoTransferencia }}</td> -->
                        <td>{{ m.codMotivoTransferenciaErp }}</td>
                        <!-- <td>{{ m.codEmpresa }}</td> -->
                        <td>{{ m.descripcion }}</td>

                        <td>
                            <button class="btn btn-info btn-circle " [routerLink]="['/motivoTransferencia/formulario', m.codMotivoTransferencia]" type="button">
                     <i class="fa fa-pencil"></i>
                   </button>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-circle " (click)='delete(m)' type="button">
                     <i class="fa fa-trash-o"></i>
                     </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container" *ngIf="motivos?.length > 0">
            <ngb-pagination class="d-flex justify-content-center" [size]="tamanhoPag" [collectionSize]="motivos.length" [(page)]="currentPage" [maxSize]="4" [boundaryLinks]="true">
                <ng-template ngbPaginationFirst>Primera</ng-template>
                <ng-template ngbPaginationLast>Última</ng-template>
            </ngb-pagination>
        </div>


    </div>
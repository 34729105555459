<div class="card border-primary mb-3">
    <div class="card-header label-themecolor">
        <h4 class="m-b-0 text-white">Listado de medios de pago( {{ medios.length}})</h4>
    </div>
    <div class="card-body text-dark">
        <div class="row form-group">
            <div class="col-3">
                <button class="btn btn-rounded   btn-success" type="button" [routerLink]="['/medioPago/formulario']">
            <i class="fa fa-plus"></i>
            Nuevo
          </button>
            </div>

            <div class="col-3"></div>
        </div>
        <div class="row" animated fadeIn *ngIf="cargando">
            <div class="col-sm-12">
                <div class="alert alert-info text-center">
                    <strong>Cargando ...</strong>
                    <br>
                    <i class="fa fa-refresh fa-spin fa-2x"></i>
                    <br>
                    <span>Espere por favor ...</span>
                </div>
            </div>
        </div>
        <div *ngIf="medios?.length == 0" class="alert alert-info">
            No se han encontrado registros ...
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-striped" *ngIf="medios?.length>0">
                <thead>
                    <tr>
                        <!--  <th>Codigo</th>
            <th>Codigo empresa</th> -->
                        <th>Codigo Erp</th>
                        <th>Medio pago</th>
                        <th>Cheque</th>
                        <th>Banco</th>
                        <th>Ref.</th>
                        <th>Tipo</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let medio of medios  | slice: (currentPage * pageSize) - pageSize :currentPage * pageSize">
                        <!-- <td>{{ medio.codMedioPago }}</td>
            <td>{{ medio.codEmpresa }}</td> -->
                        <td>{{ medio.codMedioPagoErp }}</td>
                        <td>{{ medio.descripcion }}</td>
                        <td *ngIf="medio.esCheque === true">Si</td>
                        <td *ngIf="medio.esCheque === false">NO</td>
                        <td *ngIf="medio.tieneBanco === true">Si</td>
                        <td *ngIf="medio.tieneBanco === false">NO</td>
                        <td *ngIf="medio.tieneRef === true">Si</td>
                        <td *ngIf="medio.tieneRef === false">NO</td>
                        <td *ngIf="medio.tieneTipo === true">Si</td>
                        <td *ngIf="medio.tieneTipo === false">NO</td>

                        <!--   <td>{{ cliente.fechaCreacion  | date:'dd/MM/y, h:mm a'}}</td>
            <td>{{ cliente.fechaModificacion  | date:'dd/MM/y, h:mm a'}}</td> -->
                        <!--     <td *ngIf="cliente.obs" >{{cliente.obs}}</td>
            <td *ngIf="!cliente.obs" >xxxxxxx</td> -->

                        <td>
                            <button class="btn btn-info btn-circle " [routerLink]="['/medioPago/formulario', medio.codMedioPago]" type="button">
                      <i class="fa fa-pencil"></i>
                 </button>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-circle " (click)='delete(medio)' type="button">
                      <i class="fa fa-trash-o"></i>
                   </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container" *ngIf="medios?.length > 0">
            <ngb-pagination class="d-flex justify-content-center" [size]="tamanhoPag" [collectionSize]="medios.length" [(page)]="currentPage" [maxSize]="4" [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                <ng-template ngbPaginationNext>&raquo;</ng-template>
                <ng-template ngbPaginationFirst>Primera</ng-template>
                <ng-template ngbPaginationLast>Última</ng-template>
            </ngb-pagination>
        </div>


    </div>
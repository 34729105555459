 
<div class="card ">
    <div class="card-header label-themecolor">
        <h4 *ngIf="!listaPrecio.codListaPrecio" class="m-b-0 text-white">Crear lista precio</h4>
        <h4 *ngIf="listaPrecio.codListaPrecio" class="m-b-0 text-white">Actualizar lista precio</h4>
    </div>
    <div class="card-body">

        <form #listaPrecioForm="ngForm">

            <div class="form-group row">
                <label for="codListaPrecioErp" class="col-form-label col-sm-2">Codigo Erp <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="listaPrecio.codListaPrecioErp" (ngModelChange)="listaPrecio.codListaPrecioErp =toUpeCaseEvent($event)" name="codListaPrecioErp" #codListaPrecioErp="ngModel" required minlength="1">
                    <div class="alert alert-danger" *ngIf="codListaPrecioErp.invalid && (codListaPrecioErp.dirty || codListaPrecioErp.touched)">
                        <div *ngIf="!!codListaPrecioErp.errors.required">
                            codListaPrecioErp es requerido
                        </div>
                        <div *ngIf="!!codListaPrecioErp.errors.minlength">
                            codListaPrecioErp debe tener al menos 1 caracteres
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="descripcion" class="col-form-label col-sm-2">Lista <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="listaPrecio.descripcion" (ngModelChange)="listaPrecio.descripcion = toUpeCaseEvent($event)" name="descripcion" #descripcion="ngModel" required minlength="5">
                    <div class="alert alert-danger" *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)">
                        <div *ngIf="!!descripcion.errors.required">
                            descripcion es requerido
                        </div>
                        <div *ngIf="!!descripcion.errors.minlength">
                            descripcion debe tener al menos 5 caracteres
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="ecommerce" class="col-form-label col-sm-2">Ecommerce  <span style="color: red">(*)</span></label>
                <div class="col-sm-6">
                    <select class=" custom-select  form-control" name="ecommerce" id="selectecommerce" [(ngModel)]="listaPrecio.ecommerce" #ecommerce="ngModel">
                                <option value="true">SI</option>
                                <option value="false" >NO</option>
                              </select>
                </div>
            </div>


            <div class="form-group row button-group">
                <div class="col-sm-6">
                    <button type="button" [routerLink]="['/listaPrecio']" class="btn btn-danger waves-effect waves-light"><i class="fa  fa-arrow-circle-left"></i> Atrás</button>
                    <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='create()' *ngIf="!listaPrecio.codListaPrecio else elseBlock" [disabled]="!listaPrecioForm.form.valid"><i class="fa   fa-floppy-o"></i>Crear</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-info waves-effect waves-light" type="button" role="button" (click)='update()' [disabled]="!listaPrecioForm.form.valid"><i class="fa   fa-floppy-o"></i>Actualizar</button>
                    </ng-template>
                </div>
            </div>


        </form>

    </div>
</div>
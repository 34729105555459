
export class MedioPago {
    //  ? significa opcional
           public codMedioPago: number;
            public codMedioPagoErp: string;
            public codEmpresa: number;
            public descripcion: string;
            public tieneTipo: boolean;
            public tieneRef: boolean;
            public tieneBanco: boolean;
            public esCheque: boolean;
            public esObsequio: boolean;
            constructor() { }
    }
